import { Dialog, Button } from 'primereact';
import styles from "./index.module.scss";
import DepositQRCode from '../QRCode';
import { useState } from 'react';

export default function DepositInfo(props) {
    const [showDepositQRCodeModel, setShowDepositQRCodeModel] = useState(false);
    return (
        <>
            <Dialog closable={false} showHeader={false} className={styles.main} contentClassName={styles.contentClass} appendTo="self" visible={props.showDepositInfoModel} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: "40vw" }} onHide={() => props.setShowDepositInfoModel(false)}>
                <div className={styles.header}>
                    <i className='pi pi-times' onClick={() => props.setShowDepositInfoModel(false)} />
                </div>
                <div className={styles.upperInfo}>
                    <div className={styles.title}>
                        Depositing Funds
                    </div>
                    <p className={styles.description}>
                        Ensure this before depositing funds to your Beyond Wallet Address. Failing to do so might lead to your funds getting get stuck
                    </p>
                </div>
                <div className={styles.bottomInfo}>
                    <div className={styles.item}>
                        <h1>
                            <img src="/assets/icons/wallets/!.png" alt='!' />
                            Supported Chains
                        </h1>
                        <p>
                            Make sure you send only the assets bridged to the following Chains.
                        </p>
                        <div className={styles.chains}>
                            {/* <div className={styles.chain}>
                                <img src="/assets/icons/wallets/beyondl3.png" alt="basel2" />
                                Beyond Chain
                            </div> */}
                            <div className={styles.chain}>
                                <img src="/assets/icons/wallets/basel2.png" alt="beyondl3" />
                                Base L2
                            </div>
                        </div>
                        <a href="https://bridge.bullieverse.com/" target="_blank" rel="noreferrer" className={styles.guide}>Bridge $Bull to Base</a>
                    </div>
                    <div className={styles.item}>
                        <h1>
                            <img src="/assets/icons/wallets/!.png" alt='!' />
                            Supported Assets
                        </h1>
                        <p>
                            These are the only supported assets on Beyond Wallet at the moment.
                        </p>
                        <div className={styles.chains}>
                            <div className={styles.chain}>
                                <img src="/assets/icons/wallets/bull.png" alt="bull" />
                                BULL
                            </div>
                            <div className={styles.chain}>
                                <img src="/assets/icons/wallets/usdc.png" alt="eth" />
                                USDC
                            </div>

                        </div>
                    </div>
                </div>

                <Button label="I understand, Proceed" className="w-full mt-3" onClick={() => {
                    setShowDepositQRCodeModel(true);
                    props.setShowDepositInfoModel(false);
                }} />

            </Dialog>
            <DepositQRCode
                userDetails={props.userDetails}
                defaultChain={props.defaultChain}
                client={props.client}
                showDepositQRCodeModel={showDepositQRCodeModel}
                setShowDepositQRCodeModel={(e) => setShowDepositQRCodeModel(e)}
            />
        </>
    )
}