import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import { Button } from "primereact";
export default function SignupSuccess() {
    const navigate = useNavigate();
    return (
        <div className="container">
            <div className={styles.main}>
                <img src="/assets/banners/final.png" alt="banner" className={styles.banner} />
                <h2>Welcome to the Future of Gaming!</h2>
                <p>
                    Your Beyond account has been successfully created. Welcome aboard!
                </p>
                <Button label="Continue" className={styles.continueButton} onClick={() => navigate("/")} />
            </div>
        </div>
    )
}