import { useState } from 'react';
import styles from './index.module.scss';
import { Dialog } from 'primereact/dialog';
export default function HowItWorksModal(props) {
    const [activetab, setActiveTab] = useState(0)
    const header = () => {
        return (
            <div className='flex justify-content-between align-items-center text-lg'>
                How it Works
            </div>
        )
    };

    return (
        <div className={styles.main}>
            <Dialog
                maskClassName={styles.maskClassName}
                className={styles.modalClass}
                contentClassName={styles.contentClassName}
                headerClassName={styles.header}
                header={header}
                visible={props.showHowItWorksModal}
                breakpoints={{ '960px': '75vw', '640px': '100vw' }}
                style={{ width: "45vw" }}
                onHide={() => { props.setShowHowItWorksModal(false) }}
                appendTo="self"
            >
                <div className={styles.body}>
                    <div className={styles.left}>
                        <div className={styles.lists}>
                            <div className={`${styles.item} ${activetab === 0 && styles.active} `} onClick={() => setActiveTab(0)}>
                                <img src={`/assets/icons/pointfarm/HUT/${activetab === 0 ? "BeyondPoints_Active" : "BeyondPoints"}.png`} alt='icons' />
                                Beyond Points
                            </div>
                            <div className={`${styles.item} ${activetab === 1 && styles.active} `} onClick={() => setActiveTab(1)}>
                                <img src={`/assets/icons/pointfarm/HUT/${activetab === 1 ? "Multiplier_Active" : "Multiplier"}.png`} alt='icons' />
                                Multiplier
                            </div>
                            <div className={`${styles.item} ${activetab === 2 && styles.active} `} onClick={() => setActiveTab(2)}>
                                <img src={`/assets/icons/pointfarm/HUT/${activetab === 2 ? "Withdrawal_Active" : "Withdrawal"}.png`} alt='icons' />
                                Withdrawal
                            </div>
                            <div className={`${styles.item} ${activetab === 3 && styles.active} `} onClick={() => setActiveTab(3)}>
                                <img src={`/assets/icons/pointfarm/HUT/${activetab === 3 ? "BeyondNodes_Active" : "BeyondNodes"}.png`} alt='icons' />
                                Beyond Nodes
                            </div>
                            <div className={`${styles.item} ${activetab === 4 && styles.active} `} onClick={() => setActiveTab(4)}>
                                <img src={`/assets/icons/pointfarm/HUT/${activetab === 4 ? "BeyondTokens_Active" : "BeyondTokens"}.png`} alt='icons' />
                                Beyond Tokens
                            </div>
                        </div>
                    </div>
                    {activetab === 0 && (
                        <div className={styles.right}>
                            <div className={styles.title}>
                                Beyond Points
                            </div>
                            <p>
                                Earn Beyond Points by caching (staking) your Bull Tokens. The number of Beyond Points you earn is proportional to the total amount of Bull Tokens you have cached. For instance, you might earn 1 Beyond Point per hour for each Bull Token cached.
                            </p>
                            <p>
                                Your share of the Beyond Tokens airdropped to the Bullieverse community will be determined by the proportion of Beyond Points you've earned relative to the total number of Beyond Points earned by all participants. To maximize your potential share, cache as many Bull Tokens as possible for an extended period.
                            </p>
                        </div>
                    )}

                    {activetab === 1 && (
                        <div className={styles.right}>
                            <div className={styles.title}>
                                Multiplier
                            </div>

                            <p>
                                The Beyond Multiplier is a value that boosts your Beyond Points earnings. It starts at 1x and increases by 0.05x every week. Your earned Beyond Points are multiplied by this value, and the resulting total points determine your share of the Beyond Token airdrop.
                            </p>
                            <p>
                                To maximize your rewards, start caching your Bull Tokens as early as possible and keep them staked for a long time. The longer you stake, the higher your multiplier will be, resulting in more Beyond Points and a larger share of the airdropped Beyond Tokens.
                            </p>
                        </div>
                    )}

                    {activetab === 2 && (
                        <div className={styles.right}>
                            <div className={styles.title}>
                                Withdrawal
                            </div>
                            <p>
                                To withdraw your cached Bull Tokens, you'll need to initiate the DeCaching process. You can DeCache any portion of your cached tokens, allowing for flexible management of your staked assets. Once you've initiated the DeCaching process, there is a one-week waiting period before your tokens are fully DeCached and available for withdrawal.
                            </p>
                            <p>
                                During the waiting period, you can track the progress of your DeCaching request in the DeCache queue, located under the withdraw card in your account dashboard. After the one-week period has elapsed, your DeCached Bull Tokens will be available for withdrawal at any time. Please note that DeCached tokens will no longer earn Beyond Points, so it's best to keep your tokens cached for as long as possible to maximize your rewards.
                            </p>
                        </div>
                    )}

                    {activetab === 3 && (
                        <div className={styles.right}>
                            <div className={styles.title}>
                                Beyond Nodes
                            </div>
                            <p>
                                In addition to Beyond Tokens, participants can also earn Beyond Guardian Nodes as rewards. The number of nodes each participant receives will be displayed on the leaderboard.
                            </p>
                            <p>
                                Beyond Guardian Nodes ensure trustless verification for AI inference on the Beyond Network by participating in the interactive verification game. As a node operator, you can either:
                            </p>
                            <ol>
                                <li>
                                    Run the Beyond Network node yourself, contributing directly to the network's functionality.
                                </li>
                                <li>
                                    Delegate your node to a service provider, sharing a portion of your rewards with them in exchange for their services.
                                </li>
                            </ol>

                            <p>
                                By participating in the network through running a Beyond Guardian Node or delegating it to a service provider, you'll be eligible for rewards in the form of $B tokens. These rewards incentivize node operators to actively contribute to the network.
                            </p>
                        </div>
                    )}

                    {activetab === 4 && (
                        <div className={styles.right}>
                            <div className={styles.title}>
                                Beyond Tokens
                            </div>
                            <p>
                                Beyond Tokens ($B) are the ecosystem tokens powering the Beyond Network. They serve two primary functions:
                            </p>
                            <ol>
                                <li>
                                    Governance: $B holders can participate in shaping the future of the Beyond ecosystem.
                                </li>
                                <li>
                                    Value accrual: As the ecosystem grows, $B captures value from network activities.
                                </li>
                            </ol>
                            <p>
                                Beyond Network aims to democratize AI access through decentralization. It offers open-source models and AI-powered applications on decentralized compute, allowing users to pay only for inference. The ecosystem welcomes developers to build applications and individuals to contribute compute power, creating a truly permissionless and inclusive AI infrastructure.
                            </p>

                        </div>
                    )}
                </div>
            </Dialog >
        </div >
    )
}