import Loader from "Components/Loader";
import configuration from "Config";
import axios from "axios/axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

export default function GameLogin() {

    const params = useParams();
    const searchParams = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const parseParams = async () => {
            let { loginType } = params;
            await localStorage.removeItem("beyond_user");
            localStorage.setItem("isGameLogin", true);
            if (loginType) {
                if (loginType === "createAccount") {
                    navigate("/auth");
                } else {
                    let searchParam = searchParams[0];
                    try {
                        let { response: { data } } = await axios.post(`/auth/${loginType}CallBack`, {
                            "code": searchParam.get("code"),
                            "redirectUri": `${configuration.GAME_AUTH_REDIRECT_URL}/${loginType}`,
                        });
                        await localStorage.setItem("beyond_user", JSON.stringify(data));
                        setTimeout(() => {
                            navigate("/gamelogin/launch")
                        }, 2000);
                    } catch (error) {
                        let email = error?.error?.email ?? undefined;
                        if (email) {
                            dispatch({
                                type: "addNewAuthFlowUserDetails", payload: {
                                    "integrationId": error?.error?.integrationId,
                                    "oauthCode": searchParam.get("code"),
                                    "redirectUri": `${configuration.AUTH_REDIRECT_URL}/${loginType}`,
                                    "oauthEmail": email,
                                    "email": email,
                                    "provider": loginType?.toUpperCase()
                                }
                            });
                            navigate("/auth/signup");
                        } else {
                            navigate("/auth");
                        }
                    }
                }
            }
        }
        parseParams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <Loader />
        </>
    )
}