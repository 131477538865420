import React from 'react'
import { Navigate, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const ProtectedRoute = ({ children }) => {
    let location = useLocation();
    let user = JSON.parse(localStorage.getItem("beyond_user") || "{}");
    if (user?.token?.accessToken) {
        const decodedToken = jwtDecode(user?.token?.accessToken);
        var dateNow = new Date();
        if (dateNow.getTime() > (decodedToken.exp * 1000)) {
            localStorage.removeItem("beyond_user");
            return <Navigate to="/auth" state={{ from: location }} replace />
        }
        return children;
    } else {
        return <Navigate to="/auth" state={{ from: location }} replace />
    };
};

export default ProtectedRoute;