import { Dialog } from 'primereact';
import styles from "./index.module.scss";
import { QRCode } from 'react-qrcode-logo';
import { useDispatch, useSelector } from 'react-redux';
import allActions from 'redux/actions';
import { useState } from 'react';

const DepositQRCode = (props) => {
    const dispatch = useDispatch();
    const { userAccountDetails } = useSelector(state => state.auth);
    const [copiedVisible, setCopiedVisible] = useState(false);

    return (
        <Dialog closable={false} showHeader={false} className={styles.main} contentClassName={styles.contentClass} appendTo="self" visible={props.showDepositQRCodeModel} style={{ width: "400px" }} onHide={() => props.setShowDepositQRCodeModel(false)}>
            <i className='pi pi-times' onClick={() => props.setShowDepositQRCodeModel(false)} />
            <div className={styles.header}>
                Deposit Funds
            </div>
            <div className={styles.qrcode}>
                <QRCode value={userAccountDetails?.smartAccount?.address} bgColor="#131418" size="300" fgColor="#fff" ecLevel="L" qrStyle="dots" logoImage="/assets/icons/smartWallet.svg" logoPadding={2} logoPaddingStyle="circle" removeQrCodeBehindLogo={true} />
            </div>

            <div className={styles.addressBox}>
                {copiedVisible
                    ? (
                        <>
                            <div className={styles.copied}>Copied!</div>
                        </>
                    ) : (
                        <>
                            {dispatch(allActions.authActions.splitWalletAddress(userAccountDetails?.smartAccount?.address))}
                        </>
                    )}
                <i className="pi pi-copy" onClick={() => {
                    navigator.clipboard.writeText(userAccountDetails?.smartAccount?.address);
                    setCopiedVisible(true);
                    setTimeout(() => {
                        setCopiedVisible(false);
                    }, 2000);
                }} />
            </div>

            <p>
                Copy the wallet address to send funds to this wallet.
            </p>

        </Dialog>
    )
}

export default DepositQRCode;