import { Button, Messages, Skeleton } from "primereact";
import styles from "./index.module.scss";
import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserAssets from "Components/UserAssets";
import SessionKeys from "Components/SessionKeys";
import DepositInfo from "Components/Modal/Deposit/DepositInfo";
import WithdrawDialog from "Components/Modal/Withdraw";
import allActions from "redux/actions";
import Credits from "Components/Credits";

export default function Wallets() {
    const toast = useRef(null);

    const [showDepositInfoModel, setShowDepositInfoModel] = useState(false);
    const [showWithdrawModel, setShowWithdrawModel] = useState(false);
    const dispatch = useDispatch();
    const { userWalletBalance, userAccountDetails, client, bullTokenPrice, ethTokenPrice } = useSelector(state => state.auth);

    return (
        <>
            {userWalletBalance?.SmartWallet?.bullBalance
                ?
                (
                    <div className={styles.rightPanel}>
                        <Messages ref={toast} />
                        <h1>
                            Beyond Wallet
                            <img src="/assets/icons/wallets/blue_heading.png" alt="icon" />
                        </h1>
                        <h2>
                            Transact fast and safe in Beyond Ecosystem with your smartwallet
                        </h2>
                        <div className={styles.box}>
                            <div className={styles.item}>
                                <div className={styles.left}>
                                    <img src="/assets/icons/wallets/wallet.png" alt="smartwallet" className={styles.icon} />
                                    <div>
                                        <div className={styles.title}>
                                            {dispatch(allActions.authActions.splitWalletAddress(userAccountDetails?.smartAccount?.address))}
                                        </div>
                                        <p>
                                            ${dispatch(allActions.authActions.parseBalance((Number(userWalletBalance?.SmartWallet?.bullBalance?.displayValue) * bullTokenPrice) + (Number(userWalletBalance?.SmartWallet?.ethBalance?.displayValue) * ethTokenPrice)))}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <Button label="Deposit" className={styles.depositButton} onClick={() => setShowDepositInfoModel(true)} />
                                    <Button disabled={userWalletBalance === null} label="Withdraw" className="p-button-secondary" onClick={() => setShowWithdrawModel(true)} />
                                </div>
                            </div>
                        </div>

                        <div className={styles.tabBar}>
                            <div className={`${styles.tab} ${styles.active}`}>
                                <img src="/assets/icons/wallets/basel2.png" alt="basel2" />
                                Base L2
                            </div>

                            <div className={`${styles.tab} custom-target-icon`}>
                                <img src="/assets/icons/wallets/beyondl3.png" alt="basel2" />
                                <span className={styles.tooltip}>Coming Soon</span>
                                Beyond Chain
                            </div>
                        </div>

                        <UserAssets />
                        <Credits />

                        <SessionKeys
                            client={client}
                        />

                        <DepositInfo
                            showDepositInfoModel={showDepositInfoModel}
                            setShowDepositInfoModel={(e) => setShowDepositInfoModel(e)}
                        />

                        <WithdrawDialog
                            showWithdrawModel={showWithdrawModel}
                            setShowWithdrawModel={(e) => setShowWithdrawModel(e)}
                        />
                    </div>
                )
                : (
                    <div className={styles.rightPanel}>
                        <Messages ref={toast} />
                        <h1>
                            Beyond Wallet
                            <img src="/assets/icons/wallets/blue_heading.png" alt="icon" />
                        </h1>
                        <h2>
                            Transact fast and safe in Beyond Ecosystem with your smartwallet
                        </h2>
                        <div className={styles.box}>
                            <div className={styles.item}>
                                <div className={styles.left}>
                                    <img src="/assets/icons/wallets/wallet.png" alt="smartwallet" className={styles.icon} />
                                    <div>
                                        <div className={styles.title}>
                                            <Skeleton className={`mt-2 ${styles.bigSkeleton}`} />
                                        </div>
                                        <p>
                                            <Skeleton className={`mt-2 ${styles.bigSkeleton}`} />
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <Skeleton className={styles.buttonSkeleton} />
                                    <Skeleton className={styles.buttonSkeleton} />
                                </div>
                            </div>
                        </div>

                        <div className={styles.tabBar}>
                            <div className={`${styles.tab} ${styles.active}`}>
                                <img src="/assets/icons/wallets/basel2.png" alt="basel2" />
                                Base L2
                            </div>
                            <div className={`${styles.tab}`}>
                                <img src="/assets/icons/wallets/beyondl3.png" alt="basel2" />
                                Beyond Chain
                            </div>
                        </div>

                        <UserAssets />
                        <Credits />

                    </div>
                )
            }

        </>
    )
}