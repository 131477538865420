import React, { useEffect } from "react";
import { Outlet, BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Auth from "pages/NewAuth";
import Login from "pages/NewAuth/Login";
import Signup from "pages/NewAuth/Signup";
import Username from "pages/NewAuth/Signup/Username";
import PasswordFun from "pages/NewAuth/Signup/Password";
import VerifyEmail from "pages/NewAuth/Signup/VerifyEmail";
import SignupSuccess from "pages/NewAuth/Signup/Final";
import Home from "pages/Protected/Home";
import Layout from "./Layout";
import ProtectedRoute from "./ProtectedRoute";
import LoginRoute from "./LoginRoute";
import SocialConnect from "pages/NewAuth/Signup/SocialConnect";
import Wallets from "pages/Protected/Wallets";
// import WithoutLoginVanBar from "Components/WithoutLoginNavBar";
import ResetPassword from "pages/NewAuth/ResetPassword";
import ResetVerifyEmail from "pages/NewAuth/ResetPassword/ResetVerifyEmail";
import ChangePassword from "pages/NewAuth/ResetPassword/changePassword";
import GameLogin from "pages/NewAuth/GameLogin";
import LaunchGame from "pages/NewAuth/GameLogin/LaunchGame";
import Settings from "pages/Protected/Settings";
import Rewards from "pages/Protected/Rewards";
import Profile from "pages/Protected/Profile";
import NewLogin from "pages/Protected/NewLogin";
import PointsFarm from "pages/Protected/PointsFarm";

const AppRouter = () => {

  useEffect(() => {
    if (navigator.appVersion.indexOf("Mac") > -1) {
      document.body.className += " MacBody";
    } else if (navigator.appVersion.indexOf("Windows") > -1) {
      document.body.className += " WindowsBody";
    };
  }, []);

  window.addEventListener("beforeunload", function (event) {
    if (window?.opener) {
      window.opener.postMessage("Passport window is closed", "*");
    }
  });

  return (

    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Navigate to="/auth" />} />

        <Route path="gamelogin" element={<Outlet />} >
          <Route index element={<GameLogin />} />
          <Route path=":loginType" element={<GameLogin />} />
          <Route path="launch" element={<LaunchGame />} />
        </Route>

        <Route path="/" element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }>
          <Route index element={<Wallets />} />
          <Route path="integration" element={<Home />} />
          <Route path="wallets" element={<Wallets />} />
          <Route path="settings" element={<Settings />} />
          <Route path="rewards" element={<Rewards />} />
          <Route path="profile" element={<Profile />} />
          <Route path="newLogin" element={<NewLogin />} />
          <Route path="pointsfarm" element={<PointsFarm />} />
          <Route path="integrate/:type" element={<Home />} />
        </Route>

        <Route path="auth" element={
          <LoginRoute>
            <Outlet />
          </LoginRoute>
        }>
          <Route index element={<Auth />} />

          <Route path="login" element={<Outlet />}>
            <Route index element={<Login />} />
            <Route path=":loginType" element={<Login />} />
          </Route>

          <Route path="resetPassword" element={<Outlet />} >
            <Route index element={<ResetPassword />} />
            <Route path="verifyEmail" element={<ResetVerifyEmail />} />
            <Route path="changePassword" element={<ChangePassword />} />
          </Route>

          <Route path="signup" element={<Outlet />} >
            <Route index element={<Signup />} />
            <Route path="username" element={<Username />} />
            <Route path="password" element={<PasswordFun />} />
            <Route path="verifyEmail" element={<VerifyEmail />} />
            <Route path="success" element={<SignupSuccess />} />
            <Route path="socialconnect" element={<SocialConnect />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );

}

export default AppRouter;
