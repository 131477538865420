import { ProgressSpinner } from "primereact/progressspinner";
import "./index.scss";

export default function Loader({ height }) {
    return <div style={{
        height: height || "100vh",
        display: "grid",
        placeItems: "center",
        color: "#ffffff",
        fontSize: "100px"
    }}>
        <ProgressSpinner />
    </div>
}