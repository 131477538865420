import { Button } from 'primereact/button';
import styles from './index.module.scss';
import { Dialog } from 'primereact/dialog';
import { useDispatch } from 'react-redux';
import allActions from 'redux/actions';
import { useState } from 'react';
import ConnectWallet from 'Components/PointsFarm/ConnectWallet';

export default function DeCacheBullTokenWithdrawModal(props) {

    const dispatch = useDispatch();
    const [isWithdrawing, setIsWithdrawing] = useState(false);

    const continueButtonClicked = async () => {
        setIsWithdrawing(true);
        try {
            await dispatch(allActions.authActions.withdrawAmount(props?.withdrawableAmount?.indexes));
            props.getUnlockCount();
            props.setShowWithdrawModal(false);
            setIsWithdrawing(false);
        } catch (error) {
            console.error(error)
        }
    };

    return (
        <div className={styles.main}>
            <Dialog
                visible={props.showWithdrawModal}
                onHide={() => { props.setShowWithdrawModal(false) }}
                showHeader={false}
                maskClassName={styles.maskClassName}
                className={styles.modalClass}
                contentClassName={styles.contentClassName}
                headerClassName={styles.header}
                breakpoints={{ '960px': '75vw', '640px': '100vw' }}
                style={{ width: "30vw" }}
                appendTo="self"
            >
                <div className={styles.body}>
                    <div className={styles.box}>
                        <div className={styles.info}>
                            <p>
                                Are you sure you want to <span>Withdraw</span> this amount to your wallet?
                            </p>
                            <i className='pi pi-times cursor-pointer' onClick={() => props.setShowWithdrawModal(false)} />
                        </div>

                        <div className={styles.footer}>
                            Total Withdrawable Amount
                            <div className={styles.amount}>
                                {props?.withdrawableAmount?.amount}<span>BULL</span>
                            </div>
                        </div>
                    </div>
                    <ConnectWallet>
                        <Button loading={isWithdrawing} label='Yes, Continue' className={styles.continueButton} onClick={() => continueButtonClicked()} />
                    </ConnectWallet>
                </div>
            </Dialog>
        </div>
    )
}