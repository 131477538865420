import { BULL_TOKEN_ABI } from "./abis/BULL_TOKEN_ABI";
import { STAKING_ABI } from "./abis/STAKING_ABI";

const configuration = {
    sepolia_INFURA_URL: JSON.parse(process.env.REACT_APP_BASE_INFURA_URL),
    BULL_TOKEN_ABI: BULL_TOKEN_ABI,
    STAKING_ABI: STAKING_ABI,
    STAKING_CONTRACT_ADDRESS: process.env.REACT_APP_STAKING_CONTRACT_ADDRESS,
    decacheUnlockInterval: 86400 * 7,
    FACTORY_ADDRESS: process.env.REACT_APP_FACTORY_ADDRESS,
    THIRD_WEB_CLLIENT_ID: process.env.REACT_APP_THIRD_WEB_CLIENT_ID,
    ERC20ContractAddress: process.env.REACT_APP_ERC20ContractAddress,
    defaultChainId: process.env.REACT_APP_defaultChainId,
    polygonChainId: process.env.REACT_APP_polygonChainId,
    AUTH_REDIRECT_URL: process.env.REACT_APP_AUTH_REDIRECT_URL,
    GAME_AUTH_REDIRECT_URL: process.env.REACT_APP_GAME_AUTH_REDIRECT_URL,
    SESSION_KEY_ADDRESS: process.env.REACT_APP_SESSION_KEY_ADDRESS,
    projectId: "140fad0fbfa1e7bfb7120c572bc00226",
    GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    FACEBOOK_CLIENT_ID: process.env.REACT_APP_FACEBOOK_CLIENT_ID,
    REACT_APP_ENV: process.env.REACT_APP_ENV,
    USDCTokenAddress: process.env.REACT_APP_USDC_ADDRESS,

    INFURA_ID: process.env.REACT_APP_INFURA_ID,
    ETH_INFURA_URL: process.env.REACT_APP_ETH_INFURA_URL,
    POLYGON_INFURA_URL: process.env.REACT_APP_POLYGON_INFURA_URL,
    WETH_TOKEN_ADDRESS: process.env.REACT_APP_WETH_TOKEN_ADDRESS,
    /*eslint-disable */
    passwordRegex: /^(?=.*\d)(?=.*[!.?@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    emailRegex: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    chains: [
        // {
        //     chainId: process.env.REACT_APP_ENV === "PROD" ? 137 : 80001,
        //     name: process.env.REACT_APP_ENV === "PROD" ? "Polygon Mainnet" : "Mumbai",
        //     currency: "MATIC",
        //     explorerUrl: process.env.REACT_APP_ENV === "PROD" ? "https://www.polygonscan.com/" : "https://mumbai.polygonscan.com/",
        //     rpcUrl: process.env.REACT_APP_ENV === "PROD" ? "https://polygon-rpc.com" : "https://rpc-mumbai.maticvigil.com"
        // },
        {
            chainId: process.env.REACT_APP_ENV === "PROD" ? 137 : 84532,
            name: process.env.REACT_APP_ENV === "PROD" ? "Polygon Mainnet" : "Base Sepolia",
            currency: process.env.REACT_APP_ENV === "PROD" ? "MATIC" : "ETH",
            explorerUrl: process.env.REACT_APP_ENV === "PROD" ? "https://www.polygonscan.com/" : "https://sepolia.basescan.org/",
            rpcUrl: process.env.REACT_APP_ENV === "PROD" ? "https://polygon-rpc.com" : "https://sepolia.base.org"
        }
    ],
    NETWORKS: {
        "POLYGON": {
            chainId: process.env.REACT_APP_ENV === "PROD" ? "0x89" : "0x13881",
            chainName: process.env.REACT_APP_ENV === "PROD" ? "Matic(Polygon) Mainnet" : "Mumbai",
            nativeCurrency: {
                name: "MATIC",
                symbol: "MATIC",
                decimals: 18
            },
            rpcUrls: [process.env.REACT_APP_ENV === "PROD" ? "https://polygon-rpc.com" : "https://rpc-mumbai.maticvigil.com"],
            blockExplorerUrls: [process.env.REACT_APP_ENV === "PROD" ? "https://www.polygonscan.com/" : "https://mumbai.polygonscan.com/"]
        },
        "ETH": {
            chainId: process.env.REACT_APP_ENV === "PROD" ? "0x1" : "0x5",
            chainName: "Ethereum Mainnet",
            nativeCurrency: {
                name: "ETH",
                symbol: "ETH",
                decimals: 18
            },
            rpcUrls: [
                "https://mainnet.infura.io/v3/dd79341c96a748f988ca5e3a693f81b8"
            ],
            blockExplorerUrls: [
                "https://etherscan.io"
            ]
        }
    }
};
export default configuration;
