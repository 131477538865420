import styles from './index.module.scss';
import { Dialog } from 'primereact/dialog';
export default function CacheBullTokenSuccessModal(props) {
    const header = () => {
        return (
            <div className='flex justify-content-between align-items-center text-lg'>
                Cache Bull Token
            </div>
        )
    }
    return (
        <div className={styles.main}>
            <Dialog showHeader={false} maskClassName={styles.maskClassName} className={styles.modalClass} contentClassName={styles.contentClassName} headerClassName={styles.header} header={header} visible={props.showCacheBullTokenModalSuccessmodal} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: "40vw" }} onHide={() => { props.setShowCacheBullTokenModalSuccessmodal(false) }} appendTo="self">
                <div className={styles.body}>
                    <img src='/assets/icons/pointfarm/cacheSuccessBanner.png' alt='icon' className={styles.banner} />
                    <div className="flex flex-column justify-content-between">
                        <p>
                            Your <span className={styles.amount}>{props?.cachedAmount || 0}</span> BULL tokens are <span className={styles.type}>Cached</span> successfully!
                        </p>
                        <button onClick={() => props.setShowCacheBullTokenModalSuccessmodal(false)}>Contınue</button>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}