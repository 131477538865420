import { useState } from "react"
import { sendTransaction, prepareContractCall } from "thirdweb";
import WithdwarSuccess from "./WithdwarSuccess";
import { Dialog, Button, InputText } from "primereact";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import allActions from "redux/actions";
import { NavLink } from "react-router-dom";

const WithdrawDialog = (props) => {

    const dispatch = useDispatch();
    const [showWithdrawSuccess, setShowWithdrawSuccess] = useState(false);
    const [showDropdownOptions, setShowDropdownOptions] = useState(false);
    const { userWalletBalance, ethTokenPrice, bullTokenPrice, userAccountDetails, ERC20Contract, USDCContract, userDetails } = useSelector(state => state.auth);
    const [selectedCurrency, setSelectedCurrency] = useState(userWalletBalance?.SmartWallet?.ethBalance);
    const [chooseAmount, setChooseAmount] = useState("");
    const [isWithdrawing, setIsWithdrawing] = useState(false);
    const [transactionHash, setTransactionHash] = useState("");

    // eslint-disable-next-line no-unused-vars
    const transferETH = async () => {
        try {
            setIsWithdrawing(true);
            let amount = Number(chooseAmount);
            let { transactionHash: TX } = await userAccountDetails.smartAccount.sendTransaction({
                to: userDetails?.ethAddress,
                value: (amount * Math.pow(10, 6))
            });
            dispatch(allActions.authActions.getUserWalletBalance());
            setTransactionHash(TX);
            setShowWithdrawSuccess(true);
            console.log(TX);
            props.setShowWithdrawModel(false);
        } catch (error) {
            console.error(error)
        } finally {
            setIsWithdrawing(false);
        }
    };

    const transferToken = async (tokenName) => {
        try {
            setIsWithdrawing(true);
            let amount = Number(chooseAmount);
            let transaction;

            if (tokenName === "BULL") {
                transaction = prepareContractCall({
                    contract: ERC20Contract,
                    method: "transfer",
                    params: [userDetails?.ethAddress, (amount * Math.pow(10, 18))]
                })
            } else {
                transaction = prepareContractCall({
                    contract: USDCContract,
                    method: "transfer",
                    params: [userDetails?.ethAddress, (amount * Math.pow(10, 6))]
                })
            }
            let { transactionHash: TX } = await sendTransaction({ transaction, account: userAccountDetails.smartAccount });
            dispatch(allActions.authActions.getUserWalletBalance());
            setTransactionHash(TX);
            setShowWithdrawSuccess(true);
            props.setShowWithdrawModel(false);
            console.log(TX);

            // await waitForReceipt({
            //     client: client,
            //     chain: defaultChain,
            //     transactionHash
            // });
        } catch (error) {
            console.error(error)
        } finally {
            setIsWithdrawing(false);
        }
    };

    return (
        <>
            <Dialog closable={false} showHeader={false} className={styles.main} contentClassName={styles.contentClass} appendTo="self" visible={props?.showWithdrawModel} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '500px' }} onHide={() => props.setShowWithdrawModel(false)}>

                <div className={styles.header}>
                    Withdraw Funds
                    <i className="pi pi-times" onClick={() => props.setShowWithdrawModel(false)} />
                </div>

                <div className={styles.amountBox}>

                    <div className={styles.amountLabel}>
                        Amount
                    </div>

                    <div className={styles.amount}>
                        <InputText className={styles.amountField} value={chooseAmount} onChange={(e) => setChooseAmount(e.target.value)} placeholder="Enter your amount" />
                        {/* <sub>{selectedCurrency?.symbol}</sub> */}
                    </div>
                    <div className={styles.usdPrice}>
                        ${dispatch(allActions.authActions.parseBalance(chooseAmount * (selectedCurrency?.symbol === "USDC" ? ethTokenPrice : bullTokenPrice), selectedCurrency?.symbol === "BULL" && 2))} USD
                    </div>

                    <div className={styles.percentages}>
                        <div className={styles.percentage} onClick={() => setChooseAmount(selectedCurrency?.displayValue * 25 / 100)}>
                            25%
                        </div>
                        <div className={styles.percentage} onClick={() => setChooseAmount(selectedCurrency?.displayValue * 50 / 100)}>
                            50%
                        </div>
                        <div className={styles.percentage} onClick={() => setChooseAmount(selectedCurrency?.displayValue * 75 / 100)}>
                            75%
                        </div>
                        <div className={styles.percentage} onClick={() => setChooseAmount(selectedCurrency?.displayValue * 100 / 100)}>
                            100%
                        </div>
                    </div>

                    <div className={styles.dropdown} onClick={() => setShowDropdownOptions(!showDropdownOptions)}>
                        <img src={`/assets/icons/wallets/${selectedCurrency?.symbol}.png`} alt="usdc" className={styles.logo} />
                        <div className={styles.left}>
                            <div className={styles.currency}>{selectedCurrency?.symbol}</div>
                            <div className={styles.balance}>
                                Balance: {dispatch(allActions.authActions.parseBalance(selectedCurrency?.displayValue, selectedCurrency?.symbol === "BULL" && 3))}
                                <span>(${dispatch(allActions.authActions.parseBalance((selectedCurrency?.displayValue) * (selectedCurrency?.symbol === "USDC" ? ethTokenPrice : bullTokenPrice), 3))})</span>
                            </div>
                        </div>
                        <i className={showDropdownOptions ? `pi pi-angle-down ${styles.dropArrow}` : `pi pi-angle-right ${styles.dropArrow}`} />
                    </div>

                    {showDropdownOptions && (
                        <div className={styles.dropDownOptions}>
                            <div className={styles.option} onClick={() => {
                                setSelectedCurrency(userWalletBalance?.SmartWallet?.bullBalance);
                                setShowDropdownOptions(false);
                                setChooseAmount("")
                            }}
                            >
                                <div className={styles.left}>
                                    <img src="/assets/icons/wallets/bull.png" alt="usdc" className={styles.logo} />
                                    Bullieverse<span>BULL</span>
                                </div>
                                <div className={styles.right}>
                                    {dispatch(allActions.authActions.parseBalance(userWalletBalance?.SmartWallet?.bullBalance?.displayValue, 3))} BULL
                                </div>
                            </div>
                            <div className={styles.option} onClick={() => {
                                setSelectedCurrency(userWalletBalance?.SmartWallet?.ethBalance);
                                setShowDropdownOptions(false);
                                setChooseAmount("");
                            }}
                            >
                                <div className={styles.left}>
                                    <img src="/assets/icons/wallets/usdc.png" alt="usdc" className={styles.logo} />
                                    USDC<span>USDC</span>
                                </div>
                                <div className={styles.right}>
                                    {dispatch(allActions.authActions.parseBalance(userWalletBalance?.SmartWallet?.ethBalance?.displayValue))} USDC
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className={styles.fromAndToBox}>
                    <div className={styles.label}>
                        From
                    </div>
                    <div className={styles.box}>
                        <div className={styles.left}>
                            <img src="/assets/icons/wallets/wallet.png" alt="icon" />
                            <div className={styles.info}>
                                Beyond Wallet
                                <span>
                                    {dispatch(allActions.authActions.splitWalletAddress(userAccountDetails?.smartAccount?.address))}
                                </span>
                            </div>
                        </div>
                        <div className={styles.right}>
                            <img src="/assets/icons/wallets/basel2.png" alt="icon" />
                            Base L2
                        </div>
                    </div>
                </div>
                {userDetails?.ethAddress
                    ? (
                        <div className={styles.fromAndToBox}>
                            <div className={styles.label}>
                                To
                            </div>
                            <div className={styles.box}>
                                <div className={styles.left}>
                                    <img src="/assets/icons/wallets/wallet.png" alt="icon" />
                                    <div className={styles.info}>
                                        Base Wallet
                                        <span>
                                            {dispatch(allActions.authActions.splitWalletAddress(userDetails?.ethAddress))}
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.right}>
                                    <img src="/assets/icons/wallets/basel2.png" alt="icon" />
                                    Base L2
                                </div>
                            </div>
                        </div>
                    )
                    : (
                        <div className={styles.fromAndToBox}>
                            <div className={styles.label}>
                                To
                            </div>
                            <div className={`${styles.box} ${styles.noAccountInfo}`}>
                                <div className={styles.upper}>
                                    <img src="/assets/icons/wallets/!.png" alt="icon" />
                                    Base Wallet Not Linked
                                </div>
                                <span>You can link your wallet on <NavLink to="/home">Integrations Page </NavLink></span>
                            </div>
                        </div>
                    )}


                <Button disabled={Number(chooseAmount) <= 0 || Number(chooseAmount) > Number(selectedCurrency?.displayValue)} loading={isWithdrawing} label={isWithdrawing ? "Withdrawing..." : "Withdraw"} className="w-full mt-3" onClick={() => transferToken(selectedCurrency?.symbol)} />
            </Dialog>

            <WithdwarSuccess
                userDetails={userDetails}
                userAccountDetails={userAccountDetails}
                chooseAmount={chooseAmount}
                selectedCurrency={selectedCurrency}
                transactionHash={transactionHash}
                showWithdrawSuccess={showWithdrawSuccess}
                onAdded={() => setShowWithdrawSuccess(false)}
            />
        </>
    )
}

export default WithdrawDialog;
