import { Link, useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import main from "pages/NewAuth/main.module.scss";
import { InputText, Button, Toast } from "primereact";
import { useForm } from "react-hook-form";
import axios from "axios/axios";
import { useDispatch, useSelector } from "react-redux";
import { useState, useRef } from "react";
import configuration from "Config";

export default function ChangePassword() {
    const toast = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { newAuthFlowUserDetails } = useSelector(state => state.auth);
    const [showPassword, setShowPassword] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        watch
    } = useForm({
        defaultValues: {
            email: newAuthFlowUserDetails?.email,
            otp: "",
            password: ""
        }
    });

    const onSubmit = async (form) => {
        setIsLoading(true);
        try {
            let postData = {
                "email": newAuthFlowUserDetails.email,
                "newPassword": form.password,
                "otp": form.otp
            };
            await axios.post("/auth/resetPassword", postData);
            dispatch({ type: "addNewAuthFlowUserDetails", payload: {} });
            navigate("/auth");
        } catch (error) {
            if (error?.error?.message === "Reset password token is invalid, has expired, or the OTP does not match.") {
                setError('otp', { type: 'custom', message: "Invalid OTP" });
            };
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const resendOtp = async () => {
        try {
            await axios.get(`/auth/sendResetPasswordToken?email=${newAuthFlowUserDetails?.email}`);
            toast.current.show({ severity: 'success', summary: '', detail: 'Verification email sent successfully.', life: 3000 });
        } catch (error) {
            console.error(error);
        };
    }

    return (
        <div className={main.container}>
            <Toast ref={toast} />
            <div className={main.main}>
                <div className="mobileHeader">
                    <div className="leftSide">
                        <i className="pi pi-arrow-left backIcon" onClick={() => navigate(-1)} />
                    </div>
                    <div className="rightSide">
                        <img src="/assets/icons/logo_plain.png" alt="logo" height="30px" />
                    </div>
                </div>
                <div className={styles.start}>
                    {/* <div className={styles.steps}>
                        Step 1 of 1
                    </div> */}
                    <h2>
                        Reset your password
                    </h2>
                    <p>
                        Enter a new password for your account below. Make sure to choose a strong password.
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <span className={`p-input-icon-left ${styles.inputField}`}>
                            <span className={styles.tag}>Email</span>
                            <InputText readOnly disabled value={newAuthFlowUserDetails?.email} placeholder="Please enter your email" className={styles.emailField} />
                        </span>
                        <InputText
                            name="otp"
                            id="otp"
                            placeholder="Code"
                            className={errors.otp ? `${styles.emailField} p-error` : styles.emailField}
                            autoComplete="new-password"
                            {...register("otp", {
                                autocomplete: false,
                                required: {
                                    value: true,
                                    message: "OTP is required",
                                }
                            })}
                        />
                        {errors.otp && (
                            <div className={`p-error ${styles.p_error}`} style={{ fontSize: "13px" }}>
                                {errors.otp.message}
                            </div>
                        )}

                        <span className={`mt-2 p-input-icon-right ${styles.inputField}`}>
                            <i className="pi pi-eye" onClick={() => setShowPassword(!showPassword)} />
                            <InputText
                                name="password"
                                id="password"
                                placeholder="New Password"
                                type={showPassword ? "text" : "password"}
                                autoComplete="new-password"
                                {...register("password", {
                                    required: {
                                        value: true,
                                        message: "Password is required",
                                    }
                                })}
                            />
                        </span>
                        {errors.password && (
                            <div className={`p-error ${styles.p_error}`} style={{ fontSize: "13px" }}>
                                {errors.password.message}
                            </div>
                        )}

                        <span className={`p-input-icon-right ${styles.inputField}`}>
                            <i className="pi pi-eye" onClick={() => setShowPassword(!showPassword)} />
                            <InputText
                                id="confirmPassword"
                                placeholder="Re-enter new password"
                                type={showPassword ? "text" : "password"}
                                autoComplete="new-password"
                                {...register("confirmPassword", {
                                    required: {
                                        value: true,
                                        message: "Confirm Password is required",
                                    },
                                    pattern: {
                                        value: configuration.passwordRegex,
                                        message: "Password is too weak"
                                    },
                                    validate: (val) => {
                                        if (watch('password') !== val) {
                                            return "Passwords don’t match";
                                        }
                                    },
                                })}
                            />
                        </span>
                        {errors.confirmPassword && (
                            <div className={`p-error ${styles.p_error}`} style={{ fontSize: "13px" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                    <g clip-path="url(#clip0_820_1367)">
                                        <path d="M2.00747 11.9566C1.38218 11.3527 0.883424 10.6303 0.540309 9.83156C0.197194 9.03282 0.0165901 8.17374 0.00903625 7.30445C0.00148236 6.43516 0.167129 5.57307 0.496312 4.76848C0.825495 3.96389 1.31162 3.23292 1.92632 2.61822C2.54103 2.00352 3.272 1.51739 4.07659 1.18821C4.88117 0.859024 5.74326 0.693377 6.61255 0.700931C7.48184 0.708485 8.34092 0.889088 9.13967 1.2322C9.93841 1.57532 10.6608 2.07407 11.2648 2.69937C12.4573 3.93412 13.1172 5.58788 13.1023 7.30445C13.0874 9.02102 12.3988 10.6631 11.185 11.8769C9.97116 13.0907 8.32912 13.7793 6.61255 13.7942C4.89598 13.8091 3.24223 13.1492 2.00747 11.9566ZM5.98143 4.05457V7.9827H7.2908V4.05457H5.98143ZM5.98143 9.29207V10.6014H7.2908V9.29207H5.98143Z" fill="#EA4335" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_820_1367">
                                            <rect width="13.0938" height="13.0938" fill="white" transform="translate(0.0888672 0.78125)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                {errors.confirmPassword.message}
                            </div>
                        )}

                        <div className={styles.suggestion}>
                            <li>
                                Must contain at least 8 characters
                            </li>
                            <li>
                                At least one number
                            </li>
                            <li>
                                At least one symbol
                            </li>
                        </div>

                        <div className={styles.buttons}>
                            <div className={styles.resendButtonSection}>
                                Didn’t get the email? check your spam and promotions, or resend.
                                <Button type="button" className={`p-button-secondary p-button-outlined ${styles.resendButton}`} label="Resend" onClick={() => resendOtp()} />
                            </div>
                            <Button loading={isLoading} label="Reset Password" className={styles.continueButton} />
                            <span className="goBackToAllOptions">Go back to <Link to="/auth">All Options</Link></span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}