import { useEffect, useState } from 'react';
import { Dialog, InputText, Button } from "primereact";
import styles from './index.module.scss';
import allActions from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import ConnectWallet from 'Components/PointsFarm/ConnectWallet';
const Web3Utils = require('web3-utils');

export default function CacheBullTokenModal(props) {
    const dispatch = useDispatch();
    const [userBullBalance, setUserBullBalance] = useState(0)
    const [isDepositing, setIsDepositing] = useState(false)
    const { bullTokenPrice } = useSelector(state => state.auth);
    let [chooseAmount, setChooseAmount] = useState(0);
    let [initialLoading, setInitialLoading] = useState(true);

    useEffect(() => {
        const getUserBullBalance = async () => {
            try {
                let balance = await dispatch(allActions.authActions.bullBalanceOf());
                setUserBullBalance(balance / Math.pow(10, 18));
            } catch (error) {
                console.error(error);
            } finally {
                setInitialLoading(false);
            }
        }
        getUserBullBalance();
        console.log(Web3Utils)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const depositButtonClicked = async () => {
        setIsDepositing(true);
        try {
            let allowance = await dispatch(allActions.authActions.allowance());
            let a = Web3Utils.toWei(chooseAmount, 'ether');
            if (Web3Utils.toBigInt(allowance) >= Web3Utils.toBigInt(a)) {
                await dispatch(allActions.authActions.stake(a));
                props.setShowSuccessModel(true);
                props.setShowCacheBullTokenModal(false);
                props.setCachedAmount(chooseAmount);
                props.getStakingData();
            } else {
                await dispatch(allActions.authActions.approve(a));
                setTimeout(() => {
                    depositButtonClicked();
                }, 2000);
            }
        } catch (error) {
            console.error(error);
            setIsDepositing(false);
        }
    };

    const header = () => {
        return (
            <div className='flex justify-content-between align-items-center text-lg'>
                Cache Bull Token
            </div>
        )
    };

    return (
        <div className={styles.main}>
            <Dialog focusOnShow={false} maskClassName={styles.maskClassName} className={styles.modalClass} contentClassName={styles.contentClassName} headerClassName={styles.header} header={header} visible={props.showCacheBullTokenModal} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: "30vw" }} onHide={() => { props.setShowCacheBullTokenModal(false) }} appendTo="self">
                <div className={styles.body}>
                    <div className={styles.title}>
                        Enter the amount to deposit
                    </div>
                    <div className={styles.amount}>
                        {/* <InputNumber className={styles.amountField} inputId="withoutgrouping" value={chooseAmount} onValueChange={(e) => setChooseAmount(e.value)} useGrouping={true} mode="decimal"  placeholder="Enter your amount" /> */}
                        <InputText className={styles.amountField} value={chooseAmount} onChange={(e) => setChooseAmount(e.target.value)} placeholder="Enter your amount" />
                        {/* <sub>BULL</sub> */}
                    </div>
                    <div className={styles.percentages}>

                        <div className={styles.percentage} onClick={() => setChooseAmount(userBullBalance * 25 / 100)}>
                            25%
                        </div>
                        <div className={styles.percentage} onClick={() => setChooseAmount(userBullBalance * 50 / 100)}>
                            50%
                        </div>
                        <div className={styles.percentage} onClick={() => setChooseAmount(userBullBalance * 75 / 100)}>
                            75%
                        </div>
                        <div className={styles.percentage} onClick={() => setChooseAmount(userBullBalance * 100 / 100)}>
                            100%
                        </div>
                    </div>
                </div>
                <div className={styles.token}>
                    <div className={styles.symbol}>
                        <img src="/assets/icons/pointfarm/bull.png" alt='icon' />$BULL</div>
                    <div className={styles.balance}>
                        {initialLoading
                            ? (
                                <i className="pi pi-spinner pi-spin text-2xl" />
                            ) : (
                                <>
                                    Balance: {userBullBalance.toLocaleString()} <span>(${(userBullBalance * bullTokenPrice).toLocaleString()})</span>
                                </>
                            )
                        }
                    </div>
                </div>
                <ConnectWallet>
                    <Button loading={isDepositing || initialLoading} disabled={chooseAmount <= 0 || chooseAmount > userBullBalance} className={styles.depositButton} onClick={() => depositButtonClicked()} label="Deposit" />
                </ConnectWallet>
            </Dialog>



        </div>
    )
}