import { useSelector } from "react-redux";
import styles from "./index.module.scss";
export default function Profile() {
    const { userDetails } = useSelector(state => state.auth);
    return (
        <div className={styles.rightPanel}>

            <div className={styles.profileBox}>
                <div className={styles.left}>
                    {userDetails?.avatarUri
                        ? (
                            <img src={userDetails?.avatarUri} alt="icon" />
                        )
                        : (
                            <i className={`${styles.dp} pi pi-user`} />
                        )}

                    <div>
                        <div className={styles.username}>{userDetails?.username}</div>
                        <div>Your Profile</div>
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.levelBox}>
                        <div className={styles.level}>
                            <img src="/assets/icons/profile/levels.png" alt="icon" />
                            Level 0
                        </div>
                        <div className={styles.XP}>
                            0/100 XP
                        </div>
                        <meter value={0} min={0} max={100} className={styles.meter} />
                    </div>
                    <div className={styles.badges}>
                        <div className={styles.badge}>
                            <img src="/assets/icons/profile/badges.png" alt="icon" />
                            <span>0</span> Badges
                        </div>
                        <div className={styles.badge}>
                            <img src="/assets/icons/profile/competitions.png" alt="icon" />
                            <span>0</span> Competitions
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.activityBox}>
                <div className={styles.header}>
                    Your Activity
                </div>
                {/* <div className={styles.activity}>
                    <span>
                        <img src="/assets/icons/profile/xp_earned.png" alt="icon" />
                        Earned 30XP
                    </span>
                    <span className={styles.time}>3d</span>
                </div> */}
                <div className={styles.activity}>
                    <span><img src="/assets/icons/profile/account_created.png" alt="icon" />
                        Created your BeyondID
                    </span>
                    {/* <span className={styles.time}>3d</span> */}
                </div>
            </div>

            <div className={styles.badgeTitle}>Badges</div>
            <div className={styles.BadgeBox}>
                <img src="/assets/icons/rewards/nodata.png" alt="nodata" />
                <div className={styles.nodata}>
                    No Items to Display
                </div>
            </div>

        </div>
    )
}