import { useDispatch, useSelector } from "react-redux";
import styles from "./index.module.scss";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import axios from "axios/axios";
import configuration from "Config";
import allActions from "redux/actions";

export default function Settings() {
    const toast = useRef(null);
    const { userDetails, userAccountDetails } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isChangingPassword, setIsChangingPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState({ color: "#A7A7A8", message: "Choose a strong password" });
    const [previewURL, setPreviewURL] = useState(null);

    useEffect(() => {
        setPreviewURL(userDetails?.avatarUri);
    }, [userDetails]);

    const signout = async () => {
        await userAccountDetails?.Swallet?.disconnect();
        await userAccountDetails?.personalWallet?.disconnect();
        dispatch({ type: "LOAD_USER_DETAILS", payload: [] });
        dispatch({ type: "LOAD_USER_WALLET_BALANCE", payload: {} });
        localStorage.removeItem("beyond_user");
        navigate("/");
    };

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        reset,
    } = useForm({
        defaultValues: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        }
    });

    const onSubmit = async (form) => {
        setIsChangingPassword(true)
        try {
            await axios.post("/auth/changePassword", form);
            setErrorMessage({ color: "#31C859", message: "Password Changed Successfully" });
            reset();
        } catch (error) {
            if (error?.error?.message === "Incorrect old password.") {
                setErrorMessage({ color: "#E30239", message: "Incorrect Password" });
            }
        } finally {
            setIsChangingPassword(false)
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const maxSize = 3 * 1024 * 1024; // 3MB in bytes

        if (file) {
            if (file.size > maxSize) {
                toast.current.show({ severity: 'error', summary: '', detail: 'File size exceeds 3MB limit.', life: 3000 });
                return;
            }
            const reader = new FileReader();

            reader.onloadend = async () => {
                setPreviewURL(reader.result);
                try {
                    let formData = new FormData();
                    formData.append("image", file);
                    await axios.post("/auth/uploadProfilePicture", formData);
                    dispatch(allActions.authActions.getUserDetails());
                } catch (error) {
                    console.error(error);
                }
            };

            reader.readAsDataURL(file);
        }
    };

    return (
        <div className={styles.rightPanel}>
            <Toast ref={toast} />
            <h1>
                Settings
                <img src="/assets/icons/tabler_social.png" alt="icon" />
            </h1>
            <h2>
                Manage your profile settings and configurations
            </h2>

            <div className={styles.box}>
                <div className={styles.heading}>
                    Account
                </div>
                <div className={styles.item}>
                    <div className={styles.left}>
                        <div className={styles.title}>
                            Avatar
                        </div>
                    </div>
                    <div className={styles.dp}>
                        {previewURL ? (
                            <>
                                <img src={previewURL} alt="icon" style={{ objectFit: "cover" }} />
                                <label htmlFor="fileInput" className="custom-file-upload">
                                    <i className="pi pi-file-edit" />
                                </label>
                                <input
                                    accept=".jpg, .jpeg, .png"
                                    type="file"
                                    id="fileInput"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                            </>
                        ) : (
                            <>

                                <label htmlFor="fileInput" className="custom-file-upload">
                                    <i className="pi pi-user-edit" />
                                </label>
                                <input
                                    accept=".jpg, .jpeg, .png"
                                    type="file"
                                    id="fileInput"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                            </>

                        )}
                    </div>
                </div>

                <div className={styles.item}>
                    <div className={styles.left}>
                        <div className={styles.title}>
                            Email
                        </div>
                    </div>
                    {userDetails?.email}
                </div>


                <div className={styles.item}>
                    <div className={styles.left}>
                        <div className={styles.title}>
                            Username
                        </div>
                    </div>
                    {userDetails?.username}
                </div>

            </div>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                <div className={styles.box}>
                    <div className={styles.heading}>
                        Change Password
                    </div>

                    <div className={`${styles.item} ${styles.passwords}`}>
                        <div className={styles.left}>
                            <div className={styles.title}>
                                Current Password
                            </div>
                        </div>
                        <div className="flex gap-2 w-full flex-column">
                            <InputText
                                type="password"
                                placeholder="********"
                                className={errors.oldPassword ? `${styles.inputField} ${styles.p_error_input}` : styles.inputField}
                                {...register("oldPassword", {
                                    required: {
                                        value: true,
                                        message: "Current Password is required",
                                    },
                                    pattern: {
                                        message: "Invalid format",
                                        value: configuration.passwordRegex,
                                    }
                                })}
                            />
                            {errors.oldPassword && (
                                <span className={`p-error ${styles.errorClass}`}>{errors.oldPassword.message}</span>
                            )}
                        </div>
                    </div>

                    <div className={styles.item}>
                        <div className={styles.passwordWrapper}>
                            <div className={styles.passwordField}>
                                <label>New Password</label>
                                <InputText
                                    type="password"
                                    placeholder="********"
                                    className={errors.newPassword ? `${styles.inputField} ${styles.p_error_input}` : styles.inputField}
                                    {...register("newPassword", {
                                        required: {
                                            value: true,
                                            message: "Password is required",
                                        },
                                        pattern: {
                                            message: "Invalid format",
                                            value: configuration.passwordRegex,
                                        },
                                    })}
                                />
                                {errors.newPassword && (
                                    <span className={`p-error mt-2 ${styles.errorClass}`}>{errors.newPassword.message}</span>
                                )}
                            </div>
                            <div className={styles.passwordField}>
                                <label>Repeat New Password</label>
                                <InputText
                                    type="password"
                                    placeholder="********"
                                    name="confirmPassword"
                                    className={errors.confirmPassword ? `${styles.inputField} ${styles.p_error_input}` : styles.inputField}
                                    {...register("confirmPassword", {
                                        required: {
                                            value: true,
                                            message: "Password is required",
                                        },
                                        validate: (value) => value === watch('newPassword') || 'Passwords do not match',
                                    })}
                                />

                                {errors.confirmPassword && (
                                    <span className={`p-error mt-2 ${styles.errorClass}`}>{errors.confirmPassword.message}</span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={`${styles.item} ${styles.buttons}`}>
                        <div className={styles.left}>
                            <div className={styles.passwordInfo}>
                                <div style={{ height: "15px", width: "15px", background: errorMessage?.color, borderRadius: "50%" }}></div><div style={{ color: errorMessage?.color }}>{errorMessage.message}</div>
                            </div>
                        </div>
                        <Button loading={isChangingPassword} label="Change Passsword" className="p-button-secondary" />
                    </div>

                </div>
            </form>

            <div className={styles.box}>
                <div className={styles.heading}>
                    System
                </div>
                <div className={`${styles.item} ${styles.system}`}>
                    <div className={styles.left}>
                        <img src="/assets/icons/logo_plain.png" alt="metamask" className={styles.icon} />
                        <div className={styles.title} style={{ color: "#ffffff" }}>
                            Active Account
                            <p>
                                You are signed in as {userDetails?.username}
                            </p>
                        </div>
                    </div>
                    <Button label="Sign Out" className="p-button-danger" onClick={() => signout()} />
                </div>
            </div>

        </div>
    )
}