import { Button } from 'primereact/button';
import styles from './index.module.scss';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import allActions from 'redux/actions';
import ConnectWallet from 'Components/PointsFarm/ConnectWallet';
const Web3Utils = require('web3-utils');

export default function DeCacheBullToken(props) {
    const dispatch = useDispatch();
    const [isDecaching, setIsDeCaching] = useState(false);
    const [currentStackedAmount, setCurrentStackedAmount] = useState(0);
    let [initialLoading, setInitialLoading] = useState(true);

    let [chooseAmount, setChooseAmount] = useState(0);
    const header = () => {
        return (
            <div className='flex justify-content-between align-items-center text-lg'>
                DeCache Bull Token
            </div>
        )
    };

    const deCacheButtonClicked = async () => {
        setIsDeCaching(true);
        try {
            let a = Web3Utils.toWei(chooseAmount, 'ether');
            await dispatch(allActions.authActions.unLockToken(a));
            props.setDeCachedAmount(a);
            props.setShowDeCacheBullTokenSuccessModal(true);
            props.setShowDeCacheBullTokenModal(false);
        } catch (error) {
            console.error(error);
            setIsDeCaching(false);
        }
    };

    useEffect(() => {
        const getCurrentStaked = async () => {
            try {
                let a = await dispatch(allActions.authActions.currentStaked())
                setCurrentStackedAmount(a / Math.pow(10, 18))
            } catch (error) {
                console.error(error);
            } finally {
                setInitialLoading(false);
            }
        }
        getCurrentStaked()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={styles.main}>
            <Dialog maskClassName={styles.maskClassName} className={styles.modalClass} contentClassName={styles.contentClassName} headerClassName={styles.header} header={header} visible={props.showDeCacheBullTokenModal} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: "30vw" }} onHide={() => { props.setShowDeCacheBullTokenModal(false) }} appendTo="self">
                <div className={styles.body}>
                    <div className={styles.title}>
                        Enter the amount to deposit
                    </div>
                    <div className={styles.amount}>
                        <InputText disabled={isDecaching} className={styles.amountField} value={chooseAmount} onChange={(e) => setChooseAmount(e.target.value)} placeholder="Enter your amount" />
                    </div>
                    <div className={styles.percentages}>
                        <div className={styles.percentage} onClick={() => setChooseAmount(currentStackedAmount * 25 / 100)}>
                            25%
                        </div>
                        <div className={styles.percentage} onClick={() => setChooseAmount(currentStackedAmount * 50 / 100)}>
                            50%
                        </div>
                        <div className={styles.percentage} onClick={() => setChooseAmount(currentStackedAmount * 75 / 100)}>
                            75%
                        </div>
                        <div className={styles.percentage} onClick={() => setChooseAmount(currentStackedAmount * 100 / 100)}>
                            100%
                        </div>
                    </div>
                </div>
                <div className={styles.token}>
                    <div className={styles.symbol}>
                        Cached Amount
                    </div>
                    <div className={styles.balance}>
                        {initialLoading
                            ? (
                                <i className="pi pi-spinner pi-spin text-2xl" />
                            ) : (
                                <>
                                    {currentStackedAmount.toLocaleString()} <sub>BULL</sub>
                                </>
                            )
                        }

                    </div>
                </div>
                <ConnectWallet>
                    <Button loading={isDecaching} disabled={chooseAmount > currentStackedAmount || chooseAmount <= 0} className={styles.depositButton} onClick={() => deCacheButtonClicked()} label='Start DeCaching' />
                </ConnectWallet>

                <div className={styles.infoBox}>
                    <span className='flex justify-content-center align-items-center gap-1 w-full'>
                        <i className='pi pi-info' />DeCache Process will take a week to complete
                    </span>
                    <p>
                        Your Tokens will be ready in 7 days. You can track their status in the Queue section.
                    </p>
                </div>
            </Dialog>



        </div>
    )
}