import { Link, useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import main from "pages/NewAuth/main.module.scss";
import { InputText, Button } from "primereact";
import { useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios/axios";
import { useDispatch, useSelector } from "react-redux";

export default function Username() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isUsernameAvailable, setIsUsernameAvailable] = useState(false);
    const [username, setUsername] = useState("");
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();

    const { newAuthFlowUserDetails } = useSelector(state => state.auth);

    const onSubmit = async (form) => {
        newAuthFlowUserDetails["username"] = form.username;
        dispatch({ type: "addNewAuthFlowUserDetails", payload: newAuthFlowUserDetails });
        navigate('/auth/signup/password');
    };

    const onKeyUp = async (e) => {
        if (e.target.value.length > 3) {
            setIsLoading(true);
            try {
                let { response: isUsernameRegistered } = await axios.get(`/auth/checkUserByUsername?username=${e.target.value}`);
                if (isUsernameRegistered) {
                    setError('username', { type: 'custom', message: 'This username is not available' });
                    setIsUsernameAvailable(false);
                } else {
                    setError('username', { type: 'custom', message: 'This username is available' });
                    setIsUsernameAvailable(true);
                }
            } catch (error) {
                console.error(error);
                setIsUsernameAvailable(false);
                setError('username', { type: 'custom', message: 'This username is not available' });
            } finally {
                setIsLoading(false);
                setUsername(e.target.value);
            }
        } else {
            setIsUsernameAvailable(false);
            setUsername("");
            setError('username', { type: 'custom', message: 'This username is not available' });
        }
    };

    return (
        <div className={main.container}>
            <div className={main.main}>
                <div className="mobileHeader">
                    <div className="leftSide">
                        <i className="pi pi-arrow-left backIcon" onClick={() => navigate(-1)} />
                    </div>
                    <div className="rightSide">
                        <img src="/assets/icons/logo_plain.png" alt="logo" height="30px" />
                    </div>
                </div>
                <div className={styles.start}>
                    <div className={styles.steps}>
                        Step 1 of 3
                    </div>
                    <h2>
                        Choose your Username
                    </h2>
                    <p>
                        Craft a legendary name for yourself! You can always change it later if needed. 🔥
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                        <span className="p-input-icon-right w-full">
                            <i className="pi pi-check-circle" style={{ color: (isUsernameAvailable && username.length > 0) ? "#36c63b" : "red" }} />
                            <InputText
                                onKeyDown={(e) => onKeyUp(e)}
                                id="username"
                                className={errors.username ? `${styles.emailField} p-error` : styles.emailField}
                                placeholder="i.e. example"
                                {...register("username", {
                                    required: {
                                        value: true,
                                        message: "Username is required",
                                    }
                                })}
                            />
                        </span>
                        {username.length > 0 && (
                            <>
                                {errors.username && (
                                    <div className={styles.availablity}>
                                        <span className={isUsernameAvailable ? styles.available : styles.not_available}></span>
                                        {errors.username.message}
                                    </div>
                                )}
                            </>
                        )}

                        <div className={styles.usernameUrl}>
                            <span className={styles.tag}>URL</span> beyond.com/{username}
                        </div>
                        <div className={styles.buttons}>
                            <Button disabled={!isUsernameAvailable || username.length <= 0} loading={isLoading} label="Continue" className={styles.continueButton} />
                            <span className="goBackToAllOptions">Go back to <Link to="/auth">All Options</Link></span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}