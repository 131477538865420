import { Dialog, Button } from 'primereact';
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import allActions from "redux/actions";

const WithdrawSuccess = (props) => {

    const dispatch = useDispatch();

    const { blockExplorer } = useSelector(state => state.auth);

    return (
        <Dialog closable={false} showHeader={false} className={styles.main} contentClassName={styles.contentClass} appendTo="self" visible={props?.showWithdrawSuccess} style={{ maxWidth: '400px' }} onHide={() => props.setShowWithdrawSuccess(false)}>
            <img src="/assets/icons/wallets/success_tick.png" alt='tick' className={styles.success_tick} />

            <h1>
                Transaction initiated Successfully
            </h1>

            <div className={styles.amountSection}>
                <div className={styles.label}>
                    Amount
                </div>
                <div className={styles.amount}>
                    {dispatch(allActions.authActions.parseBalance(props.chooseAmount))} {props?.selectedCurrency?.symbol}
                </div>
            </div>

            <div className={styles.fromAndToSection}>
                <div className={styles.from}>
                    <div className={styles.label}>
                        From
                    </div>
                    <div className={styles.amount}>
                        <img src="/assets/icons/wallets/beyondl3.png" alt="basel2" />
                        {dispatch(allActions.authActions.splitWalletAddress(props?.userAccountDetails?.smartAccount?.address))}
                    </div>
                </div>
                <div className={styles.from}>
                    <div className={styles.label}>
                        To
                    </div>
                    <div className={styles.amount}>
                        <img src="/assets/icons/wallets/eth.png" alt="basel2" />
                        {dispatch(allActions.authActions.splitWalletAddress(props?.userDetails?.ethAddress))}
                    </div>
                </div>
            </div>

            <a target="_blank" rel="noreferrer" href={`${blockExplorer}/tx/${props.transactionHash}`} className={styles.track}>Track Transaction</a>

            <Button label="Okay" className={styles.activateButton} onClick={() => props.onAdded()} />
        </Dialog>
    )
};

export default WithdrawSuccess;