import { Link, useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import main from "pages/NewAuth/main.module.scss";
import { InputText, Button, Toast } from "primereact";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios/axios";
import { useRef } from "react";

export default function ResetVerifyEmail() {
    const toast = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let { newAuthFlowUserDetails } = useSelector(state => state.auth);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: newAuthFlowUserDetails?.email
        }
    });

    const onSubmit = async (form) => {
        newAuthFlowUserDetails["otp"] = form.otp;
        dispatch({ type: "addNewAuthFlowUserDetails", payload: form });
        navigate("/auth/resetpassword/changePassword");
    };

    const resendOtp = async () => {
        try {
            await axios.get(`/auth/sendResetPasswordToken?email=${newAuthFlowUserDetails?.email}`);
            toast.current.show({ severity: 'success', summary: '', detail: 'Verification email sent successfully.', life: 3000 });
        } catch (error) {
            console.error(error);
        };
    }

    return (
        <div className={main.container}>
            <Toast ref={toast} />
            <div className={main.main}>
                <div className="mobileHeader">
                    <div className="leftSide">
                        <i className="pi pi-arrow-left backIcon" onClick={() => navigate(-1)} />
                    </div>
                    <div className="rightSide">
                        <img src="/assets/icons/logo_plain.png" alt="logo" height="30px" />
                    </div>
                </div>
                <div className={styles.start}>
                    <div className={styles.steps}>
                        Step 1 of 2
                    </div>
                    <h2>
                        Enter your Verification Code
                    </h2>
                    <p>
                        Enter the 6-digit code sent to your email to reset your password.
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <span className={`p-input-icon-left ${styles.inputField}`}>
                            <span className={styles.tag}>New</span>
                            <InputText readOnly value={newAuthFlowUserDetails?.email} placeholder="Please enter your email" className={styles.emailField} />
                        </span>
                        <InputText
                            id="otp"
                            placeholder="Code"
                            className={errors.otp ? `${styles.emailField} p-error` : styles.emailField}
                            {...register("otp", {
                                required: {
                                    value: true,
                                    message: "OTP is required",
                                }
                            })}
                        />
                        {errors.otp && (
                            <div className={`p-error ${styles.p_error}`} style={{ fontSize: "13px" }}>
                                {errors.otp.message}
                            </div>
                        )}

                        <div className={styles.buttons}>
                            <div className={styles.resendButtonSection}>
                                Didn’t get the email? check your spam and promotions, or resend.
                                <Button type="button" className={`p-button-secondary p-button-outlined ${styles.resendButton}`} label="Resend" onClick={() => resendOtp()} />
                            </div>
                            <Button label="Continue" className={styles.continueButton} />
                            <span className="goBackToAllOptions">Go back to <Link to="/auth">All Options</Link></span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}