import React from "react";
import { Provider } from "react-redux";
import Routes from "./routers/Router";
import { createRoot } from "react-dom/client";
import configureStore from "./redux";
import Config from "Config";

import Loader from "Components/Loader";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
// import { ThirdwebProvider } from "@thirdweb-dev/react"
// import { BaseSepoliaTestnet } from "@thirdweb-dev/chains";
import "./index.scss";

import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
// import { CoinbaseWalletAdapter, MathWalletAdapter, TrustWalletAdapter } from '@solana/wallet-adapter-wallets';
import {
  WalletModalProvider
} from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';

import { clusterApiUrl } from '@solana/web3.js';
require('@solana/wallet-adapter-react-ui/styles.css');
export const store = configureStore();

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const metadata = {
  name: 'Beyond Network',
  description: 'Welcome to Beyond Network, your gateway to a borderless digital future. With our innovative Web3 Passport app, you gain seamless access to a decentralized world where your identity and digital assets move with you effortlessly.',
  url: 'https://passport.beyondnetwork.xyz/',
  icons: ['https://passport.beyondnetwork.xyz/assets/NavBar/logo.png']
}
let projectId = "140fad0fbfa1e7bfb7120c572bc00226";
createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata,
    defaultChainId: 5,
    enableEIP6963: true,
    enableInjected: true,
    enableCoinbase: true,
    rpcUrl: process.env.REACT_APP_BASE_INFURA_URL
  }),
  chains: Config.chains,
  projectId,
  enableAnalytics: true
})


const endpoint = clusterApiUrl(WalletAdapterNetwork.Devnet);
const wallets = [new PhantomWalletAdapter()];
root.render(
  <Provider store={store}>
    <React.Suspense fallback={<Loader />}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <Routes />
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </React.Suspense>
  </Provider>,
);