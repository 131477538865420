import styles from "./index.module.scss";
import main from "pages/NewAuth/main.module.scss";
import { InputText, Button } from "primereact";
import { useForm } from "react-hook-form";
import axios from "axios/axios";
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import configuration from "Config";
import { useDispatch } from "react-redux";

export default function ResetPassword() {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: ""
        }
    });

    const searchParams = useSearchParams();
    useEffect(() => {
        let searchParam = searchParams[0];
        let type = searchParam.get("type");
        if (type === "game") {
            localStorage.removeItem("beyond_user");
        }
    }, [searchParams]);

    const onSubmit = async (form) => {
        setIsLoading(true);
        try {
            await axios.get(`/auth/sendResetPasswordToken?email=${form.email}`);
            dispatch({ type: "addNewAuthFlowUserDetails", payload: form });
            navigate("changePassword");
        } catch (error) {
            setError('email', { type: 'custom', message: error.response.data.error.message });
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={`${main.container} ${main.flexCenter}`}>
            <div className={`${main.main} ${main.flexCenter} ${main.autoHeight}`}>
                <div className={styles.start}>
                    <img src="/assets/icons/logo_plain.png" alt="logo" className={styles.logo} />
                    <div className={main.title}>
                        Forgot your Password?
                    </div>
                    <div className={styles.subTitle}>
                        Enter the email address associated with your account to receive a password reset code.
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                        <span className={styles.inputField}>
                            <span className={styles.tag}>Email</span>
                            <InputText
                                id="email"
                                type="email"
                                className={errors.email ? `${styles.emailField} p-error` : styles.emailField}
                                placeholder="i.e. example@mail.com"
                                {...register("email", {
                                    required: {
                                        value: true,
                                        message: "Email is required",
                                    },
                                    pattern: {
                                        message: "Invalid email address",
                                        value: configuration.emailRegex,
                                    },
                                })}
                            />
                            {errors.email && (
                                <span className={`p-error ${styles.errorClass}`}>{errors.email.message}</span>
                            )}
                        </span>
                        <Button type="submit" loading={isLoading} label="Continue" className={styles.continueButton} />
                    </form>
                    <div className={styles.forgotPassword}>
                        Remember Password?
                        <NavLink to="/auth" >Sign In</NavLink>
                    </div>
                </div>
            </div>
        </div >
    )
}