import { useSelector } from "react-redux";
import { Skeleton } from "primereact";
import styles from "./index.module.scss";
export default function Credits() {
    const { USER_POINTS } = useSelector(state => state.auth);
    return (
        <>
            {USER_POINTS?.totalPoints
                ? (
                    <div className={styles.box}>
                        <div className={styles.header}>
                            <div className={styles.title}>
                                Credits
                            </div>
                        </div>
                        <table className={styles.table} cellSpacing="0">
                            <thead>
                                <tr>
                                    <th className={styles.name}>
                                        Asset
                                    </th>
                                    <th className={styles.value}>
                                        Balance
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={styles.name}>
                                        <div>
                                            <img src="/assets/icons/wallets/rush_credits.svg" alt="logo" />Rush Credits
                                        </div>
                                    </td>
                                    <td className={styles.value}>
                                        {USER_POINTS?.totalPoints || 0}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.name}>
                                        <div>
                                            <img src="/assets/icons/wallets/beyond_credits.svg" alt="logo" />Beyond Credits
                                        </div>
                                    </td>
                                    <td className={styles.value}>
                                        0
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
                : (
                    <div className={styles.box}>
                        <div className={styles.header}>
                            <div className={styles.title}>
                                Credits
                            </div>
                        </div>
                        <table className={styles.table} cellSpacing="0">
                            <thead>
                                <tr>
                                    <th className={styles.name}>
                                        Asset
                                    </th>
                                    <th className={styles.value}>
                                        Balance
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={styles.name}>
                                        <div>
                                            <img src="/assets/icons/wallets/rush_credits.svg" alt="logo" />Rush Credits
                                        </div>
                                    </td>
                                    <td className={styles.value}>
                                        <Skeleton className={styles.skeleton} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.name}>
                                        <div>
                                            <img src="/assets/icons/wallets/beyond_credits.svg" alt="logo" />Beyond Credits
                                        </div>
                                    </td>
                                    <td className={styles.value}>
                                        <Skeleton className={styles.skeleton} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}

        </>
    )
}