import { Link, useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import main from "pages/NewAuth/main.module.scss";
import { InputText, Button } from "primereact";
import { useForm } from "react-hook-form";
import axios from "axios/axios";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import configuration from "Config";

export default function PasswordFun() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const { newAuthFlowUserDetails } = useSelector(state => state.auth);
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (form) => {
        setIsLoading(true);
        try {
            await axios.post("/auth/verificationToken/getForSignUp", { email: newAuthFlowUserDetails.email })
            newAuthFlowUserDetails["password"] = form.password;
            dispatch({ type: "addNewAuthFlowUserDetails", payload: newAuthFlowUserDetails });
            navigate('/auth/signup/verifyEmail');
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={main.container}>
            <div className={main.main}>
                <div className="mobileHeader">
                    <div className="leftSide">
                        <i className="pi pi-arrow-left backIcon" onClick={() => navigate(-1)} />
                    </div>
                    <div className="rightSide">
                        <img src="/assets/icons/logo_plain.png" alt="logo" height="30px" />
                    </div>
                </div>
                <div className={styles.start}>
                    <div className={styles.steps}>
                        Step 2 of 3
                    </div>
                    <h2>
                        Set your password
                    </h2>
                    <p>
                        Add a way to protect your account. Set a password that nobody can guess. 😉
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <span className={`p-input-icon-left ${styles.inputField}`}>
                            <span className={styles.tag}>New</span>
                            <InputText readOnly value={newAuthFlowUserDetails?.email} placeholder="Please enter your email" className={styles.emailField} />
                        </span>
                        <span className={`p-input-icon-right ${styles.inputField}`}>
                            <i className="pi pi-eye" onClick={() => setShowPassword(!showPassword)} />
                            <InputText
                                id="password"
                                placeholder="Enter your password"
                                type={showPassword ? "text" : "password"}
                                className={errors.password ? "p-error" : ''}
                                {...register("password", {
                                    required: {
                                        value: true,
                                        message: "Password is required",
                                    },
                                    pattern: {
                                        message: "Invalid format",
                                        value: configuration.passwordRegex,
                                    },
                                })}
                            />
                        </span>
                        {errors.password && (
                            <span className={`p-error ${styles.errorClass}`}>{errors.password.message}</span>
                        )}
                        <div className={styles.suggestion}>
                            <li>
                                Must contain at least 8 characters
                            </li>
                            <li>
                                At least one number
                            </li>
                            <li>
                                At least one capital letter
                            </li>
                            <li>
                                At least one of these symbols (!.?@#$%^&*)
                            </li>
                        </div>
                        <div className={styles.buttons}>
                            <Button loading={isLoading} label="Continue" className={styles.continueButton} />
                            <span className="goBackToAllOptions">Go back to <Link to="/auth">All Options</Link></span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}