import { Accordion, AccordionTab, Button, ConfirmDialog, confirmDialog, Toast } from 'primereact';
import styles from "./index.module.scss";
import { useEffect, useRef, useState } from 'react';
import ActivateSesionKey from 'Components/Modal/SessionKey/ActivateSessionKey';
import ActivateSessionKeySuccess from 'Components/Modal/SessionKey/ActivateSessionKeySuccess';
import { removeSessionKey } from 'thirdweb/extensions/erc4337';
import { sendTransaction, getContract } from "thirdweb";

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import allActions from 'redux/actions';

export default function SessionKeys() {
    const [activeIndex, setActiveIndex] = useState(null);
    const [showActivateSesionKey, setShowActivateSesionKey] = useState(false);
    const [showActivateSesionKeySuccess, setShowActivateSesionKeySuccess] = useState(false);
    const toast = useRef(null);
    let { userAccountDetails, defaultChain, client, allSessionKeys, ACTIVATING_SESSION_KEY } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    useEffect(() => {
        const getAllSessionKeys = async () => {
            let length = await dispatch(allActions.authActions.getAllSessionKeys());
            if (length <= 0) {
                let l = await dispatch(allActions.authActions.getAllSigner());
                if (l <= 0) {
                    await dispatch(allActions.authActions.addUserSessionKey(3));
                    dispatch(allActions.authActions.getAllSessionKeys());
                }
            }
        }
        if (userAccountDetails?.Swallet) {
            getAllSessionKeys()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userAccountDetails]);

    const removeUserSessionKey = async (index) => {
        confirmDialog({
            message: 'Do you want to revoke this key?',
            header: 'Revoke Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            rejectClassName: 'p-button-secondary',
            accept: async () => {
                allSessionKeys[index]["isRevoking"] = true;
                dispatch({ type: "LOAD_USER_SESION_KEYS", payload: allSessionKeys });
                try {
                    const transaction = removeSessionKey({
                        contract: getContract({
                            address: userAccountDetails.smartAccount.address,
                            chain: defaultChain,
                            client: client,
                        }),
                        account: userAccountDetails.smartAccount,
                        sessionKeyAddress: allSessionKeys[index].signer
                    });
                    let { transactionHash } = await sendTransaction({ transaction, account: userAccountDetails.smartAccount });
                    console.log(transactionHash);
                    dispatch(allActions.authActions.getAllSessionKeys());
                    toast.current.show({ severity: 'success', summary: '', detail: 'You have successfully revoked session key', life: 3000 });
                } catch (error) {
                    console.error(error);
                }
            }
        });
    };

    const formatTime = (time) => {
        time = Number(time);
        return moment(time * 1000).format("DD MMMM YYYY")
    };

    const getTimeDifference = (startDate1, endDate1) => {
        startDate1 = moment(startDate1).format("YYYY-MM-DD");
        endDate1 = moment(endDate1).format("YYYY-MM-DD");

        var startDate = moment(startDate1);
        var endDate = moment(endDate1);

        var duration = moment.duration(endDate.diff(startDate));

        if (duration._data.years > 0) {
            return "Indefinite";
        } else if (duration._data.months > 0) {
            return `${duration._data.months} Months`;
        } else {
            return `${duration._data.days} Days`;
        }
    };

    const renderHeader = (index) => {
        return (
            <div className={styles.keyBoxHeader}>
                <div className={styles.keyBox}>
                    <img src="/assets/icons/wallets/key.png" alt='key' />
                </div>
                <span>
                    <div className={styles.title}>#SK000{index + 1}</div>
                    <div className={styles.dot}>

                        <img src={`/assets/icons/wallets/active_dot.png`} alt='active_dot' />Active
                    </div>
                </span>
            </div>
        )
    };

    return (
        <div className={styles.main}>
            <Toast ref={toast} />
            <ConfirmDialog className={styles.confirmDialog} />
            <Accordion multiple activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                {allSessionKeys.map((session, index) => (
                    <AccordionTab headerClassName={styles.headerClass} contentClassName={styles.contentClass} header={renderHeader(index)} key={index}>
                        <div className={styles.permissionsIncluded}>
                            <div className={styles.title}>
                                Permissions Included
                            </div>
                            <div className={styles.permissions}>
                                <div className={styles.permission}>
                                    Sign transactions
                                </div>
                                <div className={styles.permission}>
                                    Wallet info
                                </div>

                            </div>
                        </div>

                        <div className={styles.timer}>
                            <div className={styles.box}>
                                <div className={styles.title}>
                                    Active For
                                </div>
                                <div className={styles.date}>
                                    {getTimeDifference(Number(session.startTimestamp) * 1000, Number(session.endTimestamp) * 1000)}
                                </div>
                            </div>

                            <div className={styles.box}>
                                <div className={styles.title}>
                                    Activated On
                                </div>
                                <div className={styles.date}>
                                    {formatTime(session.startTimestamp)}
                                </div>
                            </div>

                            <div className={styles.box}>
                                <div className={styles.title}>
                                    Active Till
                                </div>
                                <div className={styles.date}>
                                    {formatTime(session.endTimestamp)}
                                </div>
                            </div>
                        </div>

                        <Button disabled={session?.isRevoking} label={session?.isRevoking ? "Revoking..." : "Revoke"} className="p-button-danger" onClick={() => removeUserSessionKey(index)} />
                    </AccordionTab>
                ))}

            </Accordion>
            {allSessionKeys.length <= 0 &&
                <div className={styles.needActivateBox}>
                    <div className={styles.keyBoxHeader}>
                        <div className={`${styles.keyBox} ${styles.orange}`}>
                            <img src="/assets/icons/wallets/key.png" alt='key' />
                        </div>
                        <span>
                            <div className={styles.title}>#SK000{allSessionKeys.length + 1}</div>
                            <div className={styles.dot}>
                                <img src="/assets/icons/wallets/Need Activation_dot.png" alt='active_dot' />Need Activation
                            </div>
                        </span>
                    </div>
                    <Button loading={ACTIVATING_SESSION_KEY} label={ACTIVATING_SESSION_KEY ? "Activating..." : "Activate"} onClick={() => setShowActivateSesionKey(true)} />
                </div>
            }

            <ActivateSesionKey
                showActivateSesionKey={showActivateSesionKey}
                setShowActivateSesionKey={(e) => setShowActivateSesionKey(e)}
                onActivateSuccess={(e) => {
                    setShowActivateSesionKeySuccess(true);
                    setShowActivateSesionKey(false);
                }}
            />
            <ActivateSessionKeySuccess
                showActivateSesionKeySuccess={showActivateSesionKeySuccess}
                onAdded={(e) => {
                    setShowActivateSesionKeySuccess(false);
                }}
            />
        </div>
    )
}