import { NavLink } from "react-router-dom";
import styles from "./index.module.scss";
export default function BottomBar() {
    return (
        <div className={styles.main}>
            <div className={styles.items}>
                <NavLink to="/" className={({ isActive }) => isActive ? `${styles.item} ${styles.active}` : `${styles.item}`}>
                    {({ isActive }) => (
                        <>
                            <img src={isActive ? "/assets/icons/Wallet_active.png" : "/assets/icons/Wallet.png"} alt="icon" />
                            {/* Wallet */}
                        </>
                    )}
                </NavLink>
                <NavLink to="/settings" className={({ isActive }) => isActive ? `${styles.item} ${styles.active}` : `${styles.item}`}>
                    {({ isActive }) => (
                        <>
                            <img src={isActive ? "/assets/icons/settings_active.png" : "/assets/icons/settings.png"} alt="icon" />
                            {/* Settings */}
                        </>
                    )}
                </NavLink>
                <NavLink to="/integration" className={({ isActive }) => isActive ? `${styles.item} ${styles.active}` : `${styles.item}`}>
                    {({ isActive }) => (
                        <>
                            <img src={isActive ? "/assets/icons/Integrations_active.png" : "/assets/icons/Integrations.png"} alt="icon" />
                            {/* Integrations */}
                        </>
                    )}
                </NavLink>
                <NavLink to="/rewards" className={({ isActive }) => isActive ? `${styles.item} ${styles.active}` : `${styles.item}`}>
                    {({ isActive }) => (
                        <>
                            <img src={isActive ? "/assets/icons/rewards_active.png" : "/assets/icons/rewards.png"} alt="icon" />
                            {/* Rewards */}
                        </>
                    )}
                </NavLink>
                <NavLink to="/pointsfarm" className={({ isActive }) => isActive ? `${styles.item} ${styles.active}` : `${styles.item}`}>
                    {({ isActive }) => (
                        <>
                            <img src={isActive ? "/assets/icons/points_farm_active.png" : "/assets/icons/points_farm.png"} alt="icon" />
                            {/* Profile */}
                        </>
                    )}
                </NavLink>
                <NavLink to="/profile" className={({ isActive }) => isActive ? `${styles.item} ${styles.active}` : `${styles.item}`}>
                    {({ isActive }) => (
                        <>
                            <img src={isActive ? "/assets/icons/Profile_active.png" : "/assets/icons/Profile.png"} alt="icon" />
                            {/* Profile */}
                        </>
                    )}
                </NavLink>
            </div>
        </div>
    )
}