import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Dialog } from 'primereact/dialog';
import { useDispatch } from 'react-redux';
import allActions from 'redux/actions';
import moment from 'moment';
import Config from 'Config';

export default function DeCacheBullTokenQueue(props) {
    const dispatch = useDispatch();
    const [unlocks, setUnlocks] = useState([]);
    const [isFetching, setisFetching] = useState(true);

    useEffect(() => {
        const getUnlockCount = async () => {
            try {
                let unlockCount = await dispatch(allActions.authActions.unlockCount());
                if (Number(unlockCount) > 0) {
                    let unlocks = await dispatch(allActions.authActions.getUnlocks(0, unlockCount));
                    let ul = []
                    unlocks.forEach((item, index) => {
                        if (Number(item.amount) > 0) {
                            ul.push(item);
                        }
                    })
                    setUnlocks(ul);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setisFetching(false);
            }
        };
        getUnlockCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const header = () => {
        return (
            <div className='flex justify-content-between align-items-center text-lg'>
                DeCache Queue
            </div>
        )
    };

    const getTimeDifference = (startDate1, endDate1) => {
        startDate1 = moment(startDate1 * 1000).format("YYYY-MM-DD hh:mm:ss");
        endDate1 = moment(endDate1).format("YYYY-MM-DD hh:mm:ss");
        var startDate = moment(startDate1);
        var endDate = moment(endDate1);

        var duration = moment.duration(endDate.diff(startDate));
        let data = duration._data;
        return data.days > 0 ? `${data.days} days back` : data.hours > 0 ? `${data.hours} hours back` : data.minutes > 0 ? `${data.minutes} minutes back` : "Few seconds back";
    };

    const getTimeDifference1 = (startDate1, endDate1) => {
        startDate1 = moment(startDate1).format("YYYY-MM-DD hh:mm:ss");
        endDate1 = moment(endDate1 * 1000).format("YYYY-MM-DD hh:mm:ss");
        var startDate = moment(startDate1);
        var endDate = moment(endDate1);

        var duration = moment.duration(endDate.diff(startDate));
        let data = duration._data;
        return (
            <div>
                {data?.days} <span>Days</span> {data?.hours} <span>Hour</span> {data?.minutes} <span>Min</span>
            </div>
        )
    };

    return (
        <div className={styles.main}>
            <Dialog focusOnShow={false} maskClassName={styles.maskClassName} className={styles.modalClass} contentClassName={styles.contentClassName} headerClassName={styles.header} header={header} visible={props.showDeCacheBullTokenQueueModal} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: "30vw" }} onHide={() => { props.setShowDeCacheBullTokenQueueModal(false) }} appendTo="self">
                {isFetching
                    ? (
                        <div className='flex justify-content-center align-items-center h-19rem'>
                            <i className="pi pi-spin pi-spinner" style={{ fontSize: '6rem' }} />
                        </div>
                    )
                    : (
                        <>
                            {unlocks.length > 0 ? (
                                <div className={styles.list}>
                                    {unlocks.map((item, index) => (
                                        <div className={styles.item} key={index}>
                                            <div className={styles.left}>
                                                <div className={styles.balance}>
                                                    {Number(item.amount) / Math.pow(10, 18)}
                                                    <span>BULL</span>
                                                </div>
                                                <div>{getTimeDifference(Number(item.unlockTime), moment().valueOf())}</div>
                                            </div>

                                            <div className={styles.right}>
                                                {moment().unix() > (Number(item.unlockTime) + Config.decacheUnlockInterval)
                                                    ? (
                                                        <div className={styles.right}>
                                                            <button>DeCached<i className='pi pi-check' /></button>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {getTimeDifference1(moment().valueOf(), Number(item.unlockTime) + Config.decacheUnlockInterval)}
                                                            <div class={styles.meter_container}>
                                                                <div class={styles.meter_fill} style={{ width: "80%" }}></div>
                                                            </div>
                                                        </>
                                                    )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className={styles.emptyBox}>
                                    <img src='/assets/icons/pointfarm/decacheempty.png' alt='icon' />
                                    <p>
                                        The Queue is empty as there are no items being <span>DeCached</span> right now
                                    </p>
                                </div>
                            )}
                        </>
                    )}

            </Dialog>
        </div>
    )
}