import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import main from "pages/NewAuth/main.module.scss";
import { InputText, Button } from "primereact";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios/axios";
export default function Signup() {
    const navigate = useNavigate();
    const { newAuthFlowUserDetails } = useSelector(state => state.auth);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: newAuthFlowUserDetails?.email || ""
        }
    });

    const onSubmit = async (form) => {
        setIsLoading(true)
        try {
            let { response } = await axios.get(`/auth/checkUserByEmail?email=${form.email}`)
            newAuthFlowUserDetails["email"] = form.email;
            dispatch({ type: "addNewAuthFlowUserDetails", payload: newAuthFlowUserDetails });
            navigate(response ? "/auth/login" : "/auth/signup/username");
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={main.container}>
            <div className={main.main}>
                <div className="mobileHeader">
                    <div className="leftSide">
                        <i className="pi pi-arrow-left backIcon" onClick={() => navigate("/auth")} />
                    </div>
                    <div className="rightSide">
                        <img src="/assets/icons/logo_plain.png" alt="logo" height="30px" />
                    </div>
                </div>
                <img src="/assets/banners/signup_banner.png" alt="banner" className={main.banner} />
                <div className={styles.start}>
                    <div className={`${main.title} ${main.leftTitle}`}>Create Your Beyond Account</div>
                    <p>
                        This email address doesn't have a Beyond account yet. Let's create one now!
                    </p>
                    <p>
                        It only takes a minute to get started 🥳
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                        <span className={`p-input-icon-left ${styles.inputField}`}>
                            <span className={styles.tag}>New</span>
                            <InputText
                                id="email"
                                type="email"
                                className={errors.email ? `${styles.emailField} p-error` : styles.emailField}
                                placeholder="i.e. example@mail.com"
                                {...register("email")}
                            />
                            {errors.email && (
                                <span className={`p-error ${styles.errorClass}`}>{errors.email.message}</span>
                            )}
                        </span>
                        <Button loading={isLoading} label="Continue" className={styles.continueButton} />
                    </form>

                    <p className={styles.info}>
                        By Clicking “Continue”, you acknowledge that you have read and understood, and  agree to Beyond’s T&C and Privacy Policy.
                    </p>
                </div>

            </div>
        </div>
    )
}