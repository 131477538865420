import { useEffect } from "react";
import styles from "./index.module.scss";

export default function LaunchGame() {
    let user = JSON.parse(localStorage.getItem("beyond_user") || "{}");

    useEffect(() => {
        localStorage.removeItem("isGameLogin");
    }, []);

    return (
        <div className={styles.start}>
            <a href={`beyond://login?access_token=${user?.token?.accessToken}&refresh_token=${user?.token?.refreshToken}`} className={styles.button}>Launch Game</a>
        </div>
    )
}