import { Link, useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import main from "pages/NewAuth/main.module.scss";
import { InputText, Button, Toast } from "primereact";
import { useForm } from "react-hook-form";
import axios from "axios/axios";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useState } from "react";

export default function VerifyEmail() {
    const toast = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { newAuthFlowUserDetails } = useSelector(state => state.auth);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError
    } = useForm();

    const onSubmit = async (form) => {
        setIsLoading(true);
        try {
            let postData = {
                "email": newAuthFlowUserDetails.email,
                "password": newAuthFlowUserDetails.password,
                "otp": form.otp,
                "username": newAuthFlowUserDetails.username
            };
            if (newAuthFlowUserDetails?.oauthCode) {
                postData["integrationId"] = newAuthFlowUserDetails?.integrationId;
                postData["oauthProvider"] = newAuthFlowUserDetails?.provider;
                postData["signupType"] = "oauth";
            } else {
                postData["signupType"] = "email";
            }
            let { response: { data } } = await axios.post("/auth/signUp", postData);
            if (window?.opener) {
                const message = { target: "Passport", data };
                window?.opener?.postMessage(message, "*");
                window.close();
            } else {
                localStorage.setItem("beyond_user", JSON.stringify(data));
                let isGameLogin = localStorage.getItem("isGameLogin");
                if (isGameLogin === "true" || isGameLogin === true) {
                    localStorage.removeItem("isGameLogin");
                    navigate("/gamelogin/launch");
                }
                else {
                    dispatch({ type: "addNewAuthFlowUserDetails", payload: {} });
                    navigate('/auth/signup/success');
                }
            }
        } catch (error) {
            setError('otp', { type: 'custom', message: error.error.message });
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const resendOtp = async () => {
        try {
            await axios.post("/auth/verificationToken/getForSignUp", { email: newAuthFlowUserDetails.email })
            toast.current.show({ severity: 'success', summary: '', detail: 'Verification email sent successfully.', life: 3000 });
        } catch (error) {
            console.error(error);
        };
    }

    return (
        <div className={main.container}>
            <Toast ref={toast} />
            <div className={main.main}>
                <div className="mobileHeader">
                    <div className="leftSide">
                        <i className="pi pi-arrow-left backIcon" onClick={() => navigate(-1)} />
                    </div>
                    <div className="rightSide">
                        <img src="/assets/icons/logo_plain.png" alt="logo" height="30px" />
                    </div>
                </div>
                <div className={styles.start}>
                    <div className={styles.steps}>
                        Step 3 of 3
                    </div>
                    <h2>
                        Verify your email
                    </h2>
                    <p>
                        Check your email inbox for a 6-digit verification code that we have sent and enter it below:
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <span className={`p-input-icon-left ${styles.inputField}`}>
                            <span className={styles.tag}>New</span>
                            <InputText readOnly value={newAuthFlowUserDetails?.email} placeholder="Please enter your email" className={styles.emailField} />
                        </span>
                        <InputText
                            id="otp"
                            placeholder="Code"
                            className={errors.otp ? `${styles.emailField} p-error` : styles.emailField}
                            {...register("otp", {
                                required: {
                                    value: true,
                                    message: "OTP is required",
                                }
                            })}
                        />
                        {errors.otp && (
                            <div className="p-error" style={{ fontSize: "13px" }}>
                                {errors.otp.message}
                            </div>
                        )}
                        <div className={styles.buttons}>
                            <div className={styles.resendButtonSection}>
                                Didn’t get the email? check your spam and promotions, or resend.
                                <Button type="button" className={`p-button-secondary p-button-outlined ${styles.resendButton}`} label="Resend" onClick={() => resendOtp()} />
                            </div>
                            <Button loading={isLoading} label="Continue" className={styles.continueButton} />
                            <span className="goBackToAllOptions">Go back to <Link to="/auth">All Options</Link></span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}