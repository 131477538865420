import { Dialog, Button } from 'primereact';
import styles from "./index.module.scss";
export default function ActivateSessionKeySuccess(props) {
    return (
        <Dialog closable={false} showHeader={false} className={styles.main} contentClassName={styles.contentClass} appendTo="self" visible={props?.showActivateSesionKeySuccess} style={{ maxWidth: '400px' }} onHide={() => props.setShowActivateSesionKeySuccess(false)}>
            <img src="/assets/icons/wallets/success_tick.png" alt='tick' className={styles.success_tick} />
            <h1>Session Key successfully Activated</h1>
            <p>
                You can always revoke the session key from your Wallet section.
            </p>
            <Button label="Okay" className={styles.activateButton} onClick={() => props.onAdded()} />
        </Dialog>
    )
}