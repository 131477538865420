import { Dialog, Button } from 'primereact';
import styles from "./index.module.scss";
import { useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import allActions from 'redux/actions';

const ActivateSesionKey = (props) => {

    const [duration, setDuration] = useState(3);
    const [isAdingSessionKey, setIsAdingSessionKey] = useState(false);
    const dispatch = useDispatch();

    const addUserSessionKey = async () => {
        setIsAdingSessionKey(true);
        try {
            let status = await dispatch(allActions.authActions.addUserSessionKey(duration));
            dispatch(allActions.authActions.getAllSessionKeys());
            if (status) {
                props.onActivateSuccess();
            };
        } catch (error) {
            console.error(error);
        } finally {
            setIsAdingSessionKey(false);
        }
    };

    return (
        <Dialog closable={false} showHeader={false} className={styles.main} contentClassName={styles.contentClass} appendTo="self" visible={props.showActivateSesionKey} style={{ maxWidth: '450px' }} onHide={() => props.setShowActivateSesionKey(false)}>
            <div className={styles.header}>
                Activate a Session Key
                <i className='pi pi-times' onClick={() => props.setShowActivateSesionKey(false)} />
            </div>
            <div className={styles.subHeader}>
                A session key allows you to make transactions without having to approve each time.
            </div>

            <div className={styles.timer}>
                <div className={styles.item}>
                    <div className={styles.label}>Duration</div>
                    <div className={styles.value}>{duration === 0 ? "Indefinite" : `${duration} Months`}</div>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>Active Till</div>
                    <div className={styles.value}>{duration === 0 ? "Indefinite" : moment().add(duration, "months").format("DD MMMM YYYY")}</div>
                </div>
            </div>

            <div className={styles.duration}>
                <div className={duration === 3 ? `${styles.item} ${styles.selected}` : styles.item} onClick={() => setDuration(3)}>
                    3 Months
                </div>
                <div className={duration === 6 ? `${styles.item} ${styles.selected}` : styles.item} onClick={() => setDuration(6)}>
                    6 Months
                </div>
                <div className={duration === 0 ? `${styles.item} ${styles.selected}` : styles.item} onClick={() => setDuration(0)}>
                    Indefinite
                </div>
            </div>

            <div className={styles.permissionsIncluded}>
                <div className={styles.title}>
                    Permissions Included
                </div>
                <div className={styles.permissions}>
                    <div className={styles.permission}>
                        Transactions
                    </div>
                    <div className={styles.permission}>
                        Fetch Data
                    </div>
                    <div className={styles.permission}>
                        Share Wallet ID
                    </div>
                </div>
            </div>

            <Button loading={isAdingSessionKey} label={isAdingSessionKey ? "Activating..." : "Activate"} className={styles.activateButton} onClick={() => addUserSessionKey()} />

            <div className={styles.info}>
                <img src="/assets/icons/wallets/info.png" alt='icon' />
                You can revoke your session key anytime
            </div>
        </Dialog>
    )
}

export default ActivateSesionKey;