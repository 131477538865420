import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function NewLogin() {
    const navigation = useNavigate();
    useEffect(() => {

        if (window?.opener) {
            let user = localStorage.getItem("beyond_user") ? JSON.parse(localStorage.getItem("beyond_user")) : undefined;
            if (user) {
                const message = { target: "Passport", data: user };
                window?.opener?.postMessage(message, "*");
                window.close();
            } else {
                navigation("/");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <></>
    )
}