import styles from './index.module.scss';
import { Dialog } from 'primereact/dialog';
export default function DeCacheBullTokenSuccessModal(props) {
    const header = () => {
        return (
            <div className='flex justify-content-between align-items-center text-lg'>
                Cache Bull Token
            </div>
        )
    }
    return (
        <div className={styles.main}>
            <Dialog showHeader={false} maskClassName={styles.maskClassName} className={styles.modalClass} contentClassName={styles.contentClassName} headerClassName={styles.header} header={header} visible={props.showDeCacheBullTokenSuccessModal} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: "40vw" }} onHide={() => { props.setShowDeCacheBullTokenSuccessModal(false) }} appendTo="self">
                <div className={styles.body}>
                    <img src='/assets/icons/pointfarm/decacheSuccessBanner.png' alt='icon' className={styles.banner} />
                    <div className="flex flex-column justify-content-between">
                        <p className='w-full md:w-8'>
                            <span className={styles.type}> DeCaching</span> initiated Successfully!
                        </p>
                        <div style={{ color: "var(--subtleText)" }}>
                            <span className='w-full md:w-8 block'><span className='text-white'>{props?.deCachedAmount || 0} BULL</span> Will be available to withdraw in 7 days</span>
                            <button className="mt-3" onClick={() => props.setShowDeCacheBullTokenSuccessModal(false)}>Vıew Queue</button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}