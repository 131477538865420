import { Skeleton, Tooltip } from "primereact";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import allActions from "redux/actions";

const UserAssets = () => {
    const { userWalletBalance, bullTokenPrice, ethTokenPrice, blockExplorer, userAccountDetails } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    return (
        <>
            {userWalletBalance?.SmartWallet?.bullBalance
                ? (
                    <>
                        <div className={styles.box}>
                            <div className={styles.header}>
                                <div className={styles.title}>
                                    Your Assets
                                    <Tooltip target=".custom-target-icon" />
                                    <i className="custom-target-icon pi pi-refresh" data-pr-tooltip="Refresh balance" data-pr-position="bottom" onClick={() => dispatch(allActions.authActions.getUserWalletBalance())} />
                                </div>
                                <a href={`${blockExplorer}/address/${userAccountDetails?.smartAccount?.address}`} target="_blank" rel="noreferrer" className={styles.transactionHistory}>
                                    Transaction History <img src="/assets/icons/wallets/transactionHistory.png" alt="link" />
                                </a>
                            </div>
                            <table className={styles.table} cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th className={styles.name}>
                                            Asset
                                        </th>
                                        <th className={styles.value}>
                                            Value
                                        </th>
                                        <th className={styles.amount}>
                                            Balance
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className={styles.name}>
                                            <div>
                                                <img src="/assets/icons/wallets/usdc.png" alt="logo" />USDC <span>USDC</span>
                                            </div>
                                        </td>
                                        <td className={styles.value}>
                                            ${Number(userWalletBalance?.SmartWallet?.ethBalance?.displayValue) * ethTokenPrice}
                                        </td>
                                        <td className={styles.amount}>
                                            {dispatch(allActions.authActions.parseBalance(userWalletBalance?.SmartWallet?.ethBalance?.displayValue))} USDC
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={styles.name}>
                                            <div>
                                                <img src="/assets/icons/wallets/bull.png" alt="logo" />Bullieverse <span>BULL</span>
                                            </div>
                                        </td>
                                        <td className={styles.value}>
                                            ${dispatch(allActions.authActions.parseBalance(userWalletBalance?.SmartWallet?.bullBalance?.displayValue * bullTokenPrice, 3))}
                                        </td>
                                        <td className={styles.amount}>
                                            {dispatch(allActions.authActions.parseBalance(userWalletBalance?.SmartWallet?.bullBalance?.displayValue, 3))} BULL
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                )
                : (
                    <>
                        <div className={styles.box}>
                            <div className={styles.header}>
                                <div className={styles.title}>
                                    Your Assets
                                </div>
                                <Skeleton className={styles.skeleton} />
                            </div>
                            <table className={styles.table} cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th className={styles.name}>
                                            Asset
                                        </th>
                                        <th className={styles.value}>
                                            Value
                                        </th>
                                        <th className={styles.amount}>
                                            Balance
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className={styles.name}>
                                            <div>
                                                <img src="/assets/icons/wallets/usdc.png" alt="logo" />USDC <span>USDC</span>
                                            </div>
                                        </td>
                                        <td className={styles.value}>
                                            <Skeleton className={styles.skeleton} />
                                        </td>
                                        <td className={styles.amount}>
                                            <Skeleton className={styles.skeleton} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={styles.name}>
                                            <div>
                                                <img src="/assets/icons/wallets/bull.png" alt="logo" />Bullieverse <span>BULL</span>
                                            </div>
                                        </td>
                                        <td className={styles.value}>
                                            <Skeleton className={styles.skeleton} />
                                        </td>
                                        <td className={styles.amount}>
                                            <Skeleton className={styles.skeleton} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
        </>
    )
}

export default UserAssets;