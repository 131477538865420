import axios from "axios";
const url = axios.create({ baseURL: process.env.REACT_APP_API_PREFIX });
url.interceptors.request.use(async function (config) {
    try {
        let user = localStorage.getItem("beyond_user") ? JSON.parse(localStorage.getItem("beyond_user")) : undefined;
        if (user) {
            let token = user?.token;
            config.headers = {
                authorization: token.accessToken
            };
        }
    } catch (error) {

    };
    return config;
}, function (error) {
    return Promise.reject(error);
});

url.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    return Promise.reject(error.response.data);
});

export default url;