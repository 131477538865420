import React from 'react'
import { Navigate, useLocation } from "react-router-dom"
import { jwtDecode } from "jwt-decode";

const LoginRoute = ({ children }) => {
    let user = JSON.parse(localStorage.getItem("beyond_user") || "{}");
    let location = useLocation();
    if (location) {
        if (location.pathname === "/auth/resetpassword") {
            return children;
        } else {
            if (user?.token?.accessToken) {
                const decodedToken = jwtDecode(user?.token?.accessToken);
                var dateNow = new Date();
                if (dateNow.getTime() > (decodedToken.exp * 1000)) {
                    localStorage.removeItem("beyond_user");
                    return children;
                }
                return <Navigate to="/" state={{ from: location }} replace />
            } else {
                return children;
            };
        }

    }
};

export default LoginRoute;